.contact_body{
    padding: 84px 0;
}
.contact_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.contact_bloc{
    margin-top:82px;
    width: 100%;
    display: inline-block;
    position: relative;
    border:0;
    border-radius: 15px;
    background: #FFFFFF;
    box-shadow: 0 4px 12px rgba(64,85,196,0.14); 
    padding: 98.5px 129px;
}
.contact_bloc h6{
    font-size: 18px;
    font-family: 'SF UI Text';
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    color: #393C41;
    margin-bottom: 50px;
}
.contact_bloc .form_group{
    width: 100%;
    display: inline-block;
    margin-bottom: 45px;
    position: relative;
}
.input_contact{
    width: 100%;
    display: inline-block;
    position: relative;
    height: 60px;
    border:0;
    font-family: 'SF UI Text';
    font-size: 14px;
    font-weight: 400;
    padding: 19px 0px;
    line-height: 16px;
    background-color: transparent;
    color: #393C41;
}
.form_group2 label{
    margin-bottom: 0;
    color: #A5ADBA;
    font-family: 'SF UI Text';
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top:20px;
    left:0;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
}
.form_group2{
    width: 100%;
    display: inline-block;
    position: relative;
}
.form_group2 .bar{
    width: 100%;
}
.form_group2 .bar::after{
    content: '';
    width: 0;
    height: 2px;
    display: inline-block;
    position: absolute;
    left:50%;
    right:50%;
    bottom: 0px;
    background-color: #D0555A;
    transition: 0.3s linear;
}
.form_group2 .bar::before{
    content: '';
    width: 100%;
    height: 2px;
    display: inline-block;
    position: absolute;
    left:0;
    right:0;
    bottom: 0px;
    background-color: #E4E7EC;
    transition: 0.3s linear;
}
.input_contact::placeholder,.textarea_contact::placeholder{
    color: #A5ADBA;
}
.form_group2 .input_contact:focus ~ label,
.form_group2 .input_contact.active ~ label{
    top:-10px;
    color: #D0555A;
    font-size: 12px;
}
.form_group2 .textarea_contact:focus ~ label,
.form_group2 .textarea_contact:valid ~ label{
    top:20px;
}
.form_group2 .textarea_contact:focus ~ label,
.form_group2 .textarea_contact.active ~ label{
    top:-20px;
    color: #D0555A;
    font-size: 12px;
}
.form_group2 .input_contact:focus ~.bar::after,
.form_group2 .textarea_contact:focus ~.bar::after{
    content: "";
    width: 100%;
    left: 0;
}
.textarea_contact{
    width: 100%;
    display: inline-block;
    position: relative;
    border:0;
    font-family: 'SF UI Text';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    background-color: transparent;
    transition: 0.3s linear;
    resize: none;
    color: #393C41;
    /* overflow: hidden;
    overflow-y: auto; */
}
.btn_contact{
    width: 100%;
    max-width: 118px;
    font-family: 'Helvetica Neue Medium';
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #FFFFFF;
    border:0;
    border-radius: 5px;
    background-color: #D0555A;
    cursor: pointer;
    padding: 14px 15px;
    text-align: center;
    letter-spacing: 0;
}
.btn_contact:hover,.btn_contact:focus{
    outline: 0;
    box-shadow: unset;
}
.btn_contact:disabled{
    opacity: 0.5;
    cursor: no-drop;
}
.input_contact:-moz-autofill,
.input_contact:-moz-autofill:hover, 
.input_contact:-moz-autofill:focus,
.textarea_contact:-moz-autofill,
.textarea_contact:-moz-autofill:hover,
.textarea_contact:-moz-autofill:focus{
    border: 0;
  -webkit-text-fill-color: #393C41;
  transition: background-color 5000s ease-in-out 0s;
}
.input_contact:-webkit-autofill,
.input_contact:-webkit-autofill:hover, 
.input_contact:-webkit-autofill:focus,
.textarea_contact:-webkit-autofill,
.textarea_contact:-webkit-autofill:hover,
.textarea_contact:-webkit-autofill:focus{
    border: 0;
  -webkit-text-fill-color: #393C41;
  transition: background-color 5000s ease-in-out 0s;
}
/* .errorContact{
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: #D0555A;
    margin-top: 10px;
    margin-bottom: 0;
    letter-spacing: 0.5px;
} */
/* .green-block{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 15px;
    background-color: #13B675;
    padding: 10px 15px;
} */
.green-block{
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    color: #393C41;
    text-align: left;
    margin-bottom: 0;
}
@media screen and (max-width:1023px){
    .contact_body{
        padding: 69px 0;
    }
    .contact_body h1{
        font-size: 34px;
    }
    .contact_bloc{
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        box-shadow: unset;
    }
}