/* appModal */
.appModal .modal-lg{
    max-width:1000px;
}
.appModal .modal-content{
    min-height: 735px;
    border-radius: 15px;
    background-color: #F2F2F2;
    padding: 59px 65px 0 65px;
}
.appModal .modal-body{
    padding: 0;
}
.block-app {
    width: 100%;
    display: inline-block;
    position: relative;
}
.block-app h6 {
    font-family: "SF Pro Text";
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    margin: 0;
    text-align: center;
    color: #000000;
}
.block-app .btn_bloc {
    width: 100%;
    display: block;
    max-width: 525px;
    margin: 64px auto 66px;
}
.block-app .btn_bloc .app-storeBtn, .block-app .btn_bloc .play-storeBtn{
    margin: 0;
    width: 245px;
    padding-left: 15px;
}
.block-app .btn_bloc .app-storeBtn {
    margin-right: 35px;
}
.imgs_bloc {
    width: 100%;
    display: flex;
    position: relative;
    height: 100%;
    padding: 0;
    margin: 0;
}
.imgs_bloc li {
    width: 100%;
    display: inline-block;
    position: relative;
    max-height: 430px;
    overflow: hidden;
}
.imgs_bloc li:first-child, .imgs_bloc li:last-child {
    padding-top: 45px;
}
.btn-closed{
    background-color: transparent;
    border: 0;
    display: inline-block;
    position: absolute;
    right: -35px;
    top: -60px;
    font-size: 50px;
    cursor: pointer;
}
/* appModal over */
@media screen and (min-width:768px) and (max-width:1023px){
    .appModal .modal-lg{
        max-width: 700px;
    }
    .imgs_bloc li img {
        width: 100%;
    }
    .imgs_bloc li {
        max-height: 375px;
    }
    .appModal .modal-content {
        padding: 59px 30px 0 30px;
        min-height: 673px;
    }
    .block-app h6 {
        font-size: 34px;
    }
    .btn-closed {
        right: -5px;
        top: -45px;
    }
}
@media screen and (max-width:767px){
    .appModal .modal-content{
        padding: 30px 20px 0 20px;
    }
    .block-app h6{
        font-size: 26px;
        line-height: 44px;
        margin-top: 45px;
    }
    .block-app .btn_bloc {
        margin: 70px auto 75px;
    }
    .block-app .btn_bloc .app-storeBtn, .block-app .btn_bloc .play-storeBtn {
        margin: auto;
    }
    .block-app .btn_bloc .play-storeBtn {
        margin-top: 25px;
    }
    .imgs_bloc {
        display: inline-block;
    }
    .imgs_bloc li:first-child, .imgs_bloc li:last-child {
        padding-top: 0;
    }
    .imgs_bloc li img {
        width: 100%;
    }
    .imgs_bloc li {
        max-height: 100%;
        margin-bottom: 45px;
    }
    .btn-closed {
        right: -2px;
        top: -20px;
        font-size: 50px;
    }
}