.faq_body{
    padding: 84px 0;
}
.faq_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
}
.faq_body p{
    line-height: 28px;
    max-width: 570px;
    margin: 0 auto;
    font-size: 18px;
    font-family: 'SF Pro Text';
    font-weight: 300;
    color: #444444;
    text-align: center;
}
.accordion{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    margin-top: 43px;
}
.accordion .accordion-item{
    list-style: none;
    width: 100%;
    border-radius: 8px;
    background-color: #FBFBFB;
    box-shadow: 0 5px 13px 0 rgba(64,85,196,0.14);
    padding: 15px 35px;
    margin-bottom: 25px;
}
.accordion .accordion-item .title{
    width: 100%;
    background: transparent url('../../assets/image/green-plus.png') no-repeat 0 50%;
    background-size: 25px;
    border:0;
    color: #2B3857;
    display: inline-block;
    font-family: 'Helvetica Neue Medium';
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    padding: 22px 0 22px 55px;
    text-align: left;
    transition: 0.3s linear;
    cursor: pointer;
    text-decoration: none;
}
.accordion .accordion-item.active .title{
    background: transparent url('../../assets/image/red-minus.png') no-repeat 0 50%;
    background-size: 25px;
}
.accordion .accordion-item .title::after{
    display: none;
}
.collaps_block{
    width: 100%;
    /* padding: 15px 0 25px; */
}
.collaps_block p{
    text-align: left;
    max-width: 100%;
    font-family: 'SF Pro Text';
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: #000;
    /* margin-bottom: 16px; */
}
.collaps_block p:last-child{
    margin-bottom: 0;
}
.collaps_block .sub_Ul{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
}
.collaps_block .sub_Ul li{
    list-style: none;
    width: 100%;
    padding: 0;
    margin-bottom: 16px;
    box-shadow: unset;
    border-radius: 0;
}
.collaps_block .sub_Ul li h6{
    font-family: 'SF Pro Text';
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    color: #444444;
    margin-bottom: 0;
}
.collaps_block .sub_Ul li:nth-child(2) p{
    margin-bottom: 0;
}
.accordion{
    border:0;
    border-radius: 0; 
}
.accordion .accordion-item.active .panel{
    max-height: 1200px;
}

@media screen and (max-width:767px){
    .faq_body{
        padding: 69px 0;
    }
    .faq_body h1{
        font-size: 34px;
    }
    .faq_body p.etx{
        font-size: 16px;
    }
    .faqs_ul li{
        padding: 0px 15px;
    }
    .accordion .accordion-item .title{
        background-size: 18px;
        padding: 15px 0 15px 35px;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .accordion .accordion-item.active .title{
        background-size: 18px;
    }
}