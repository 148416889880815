.discr_body{
    padding: 84px 0;
}
.discr_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.discr_ul{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    margin-top: 112px;
}
.discr_ul li{
    list-style: none;
    width:100%;
    display: inline-block;
    position: relative;
    margin-bottom: 16px;
}
.discr_ul li:last-child{
    margin-bottom: 0;
}
.discr_ul li p{
    font-family: 'SF Pro Text';
    font-size: 14px;
    margin-bottom: 0;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: justify;
    color: #000000;
}
/* .discr_ul li:nth-last-child(2) p:last-child{
    margin-top: 16px;
} */
@media screen and (max-width:767px){
    .discr_body{
        padding: 69px 0;
    }
    .discr_body h1{
        font-size: 34px;
    }
    .discr_ul{
        margin-top: 59px;
    }
}