.signal_body{
    padding: 84px 0;
}
.signal_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.signal_blo{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 90px;
}
/* .signal_menu{
    width: 100%;
    display: flex;
    position: relative;
    margin: 0;
    padding-left: 0;
    border:0;
    flex-wrap: unset;
    border-radius: 7px;
    overflow: hidden;
}
.signal_menu li{
    width: 100%;
    max-width: 328px;
    color: #9B9B9B;
    font-size: 18px;
    font-family: 'SF Pro Text';
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.39px;
    text-decoration: none;
    width: 100%;
    height: 60px;
    max-width: 328px;
    display: inline-block;
    position: relative;
    background-color: #FFFFFF;
    text-align: center;
    padding: 15px 20px;
    border: 0;
    margin-bottom: 0;
    cursor: pointer;
}
.signal_menu li.active{
    font-family: 'SF Pro Text';
    font-weight: bold;
    color: #FFFFFF;
    background-color: transparent;
    border:0;
    z-index: 1;
    transition: 0.2s linear;
}
.signal_menu .bg_signalMenu{
    width:calc((100% / 3) - 5px);
    height: 60px;
    display: inline-block;
    position: absolute;
    left:0;
    right:auto;
    background-color: #000000;
    z-index: 0;
    transition: left 0.2s linear;
}
.signal_menu .bg_signalMenu.active{
    left:calc(34% - 4px);
    right:auto;
}
.signal_menu .bg_signalMenu.active_2{
    left:calc(68% - 8px);
    right:0;
}
.signal_menu li:first-child{
    border-radius: 7px 0 0 7px;
}
.signal_menu li:nth-child(2){
    margin: 0 8px;
    border-radius: 0;
}
.signal_menu li:last-child{
    border-radius: 0 7px 7px 0;
}
.tabs-blocks{
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}
.tabs-blocks .tabs-div{
    width: 100%;
    display: inline-block;
    position: relative;
}
.tabs-blocks .tabs-div.active{
    display: block;
} */
.tabs-ul{
    width: 100%;
    display: flex;
    position: relative;
    margin: 0;
    padding-left: 0;
    border:0;
    flex-wrap: unset;
}
.tabs-ul .nav-item{
    width: 100%;
    max-width: 328px;
    display: inline-block;
}
.tabs-ul .nav-item a{
    color: #9B9B9B;
    font-size: 18px;
    font-family: 'SF Pro Text';
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.39px;
    text-decoration: none;
    width: 100%;
    height: 60px;
    max-width: 328px;
    display: inline-block;
    position: relative;
    background-color: #FFFFFF;
    text-align: center;
    padding: 15px 20px;
    border: 0 !important;
    margin-bottom: 0 !important;
}
.tabs-ul .nav-item a:hover,.tabs-ul .nav-item a:focus{
    border:0;
    border-color: transparent;
}
.tabs-ul .nav-item:first-child a {
    border-radius: 7px 0 0 7px;
}
.tabs-ul .nav-item:nth-child(2) {
    margin: 0 8px;
    border-radius: 0;
}
.tabs-ul .nav-item:nth-child(2) a{
    border-radius: 0;
}
.tabs-ul .nav-item a.active {
    font-family: 'SF Pro Text';
    font-weight: bold;
    color: #FFFFFF !important;
    background-color: #000000 !important;
    border:0;
}
.cursor-pointer{
    cursor: pointer;
}
.tabs-ul .nav-item:last-child a{
    border-radius: 0 7px 7px 0;
}
.signal-block {
    max-height: 710px;
    margin: 50px 0 82px 0; 
}
.signal-block .bon_block {
    max-height: 510px;
}
.PResult-block{
    max-height: 736px;
    margin: 50px auto 71px;
    padding: 100px 145px;
}
.sTabs-ul {
    width: 100%;
    display: flex;
    position: relative;
    padding-left: 0;
    margin: 0;
    align-items: center;
}
.sTabs-ul li{
    width: 100%;
    max-width: 125px;
    display: inline-block;
    margin: 0 6.7px;
    list-style: none;
}
.sTabs-ul li input[type="radio"]{
    display: none;
}
.sTabs-ul li label{
    width: 100%;
    border: 1px solid #F2F2F2;
    border-radius: 7px;
    background-color: #FFFFFF;
    text-align: center;
    font-family: 'SF Pro Text';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 15px;
    color: #111111;
    padding: 15px;
    cursor: pointer;
    margin-bottom: 0;
}
.sTabs-ul li input[type="radio"]:checked ~ label{
    background-color: #1A62F5;
    border-color: #1A62F5;
    color: #FFFFFF;
}
.sTabs-ul li:first-child {
    margin-left: 0;
}
.sTabs-ul li:last-child {
    margin-right: 0;
}
.dat-block {
    width: 100%;
    display: inline-block;
    position: relative;
    border: 1px solid #F2F2F2;
    margin: 40px auto 68px;
    border-radius: 15px;
    padding: 56px 43px;
}
.dat-block p {
    display: block;
    position: relative;
    margin-bottom: 30px;
    font-size: 20px;
    font-family: 'SF Pro Text';
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 24px;
    color: #000000;
}
.dat-block p .text-block {
    float: right;
    font-size: 22px;
    font-family: 'SF Pro Text';
    font-weight: bold;
    letter-spacing: -0.28px;
    line-height: 26px;
    color: #000000;
    text-align: right;
}
.dat-block p .text-block.green-text {
    color: #13B675;
}
.dat-block p:last-child {
    margin-bottom: 0;
}
.p-texts {
    width: 100%;
    display: block;
    position: relative;
    margin: 0 auto;
    max-width: 653px;
}
.p-texts p {
    font-family: 'SF Pro Text';
    font-size: 18px;
    color: #444444;
    line-height: 44px;
    letter-spacing: -0.31px;
    margin-bottom: 0;
}
.m_show2 {
    display: none;
}
.p-texts p .calc-block {
    width: 140px;
    height: 35px;
    border: 0;
    border-radius: 19px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0,0,0,0.10);
    display: inline-block;
    position: relative;
    align-items: center;
    padding: 6px 10px;
    overflow: hidden;
    margin:0 20px;
    top: 5px;
}
.p-texts p .calc-block .btn-mins, 
.p-texts p .calc-block .btn-pluss {
    padding: 5px;
    height: 100%;
    width: 20%;
    border: 0;
    background: transparent;
    margin: 0;
    display: inline-block;
    position: relative;
}
.p-texts p .calc-block .btn-mins:disabled{
    opacity: 0.5;
    cursor: no-drop;
}
.p-texts p .calc-block .input-invest {
    width: 60%;
    height: 23px;
    display: inline-block;
    position: relative;
    border: 0;
    background: transparent;
    font-size: 15px;
    font-family: 'SF Pro Text';
    font-weight: 600;
    color: #000;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}
.p-texts p .calc-block .input-invest, 
.p-texts p .calc-block .btn-mins, 
.p-texts p .calc-block .btn-pluss {
    float: left;
}
.p-texts p .calc-block .btn-mins {
    background: transparent url('../../assets/image//min-sh.svg') no-repeat 50% 50%;
}
.p-texts p .calc-block .btn-pluss {
    background: transparent url('../../assets/image/plus-sh.svg') no-repeat 50% 50%;
}
.p-texts p .p-bold {
    font-family: 'SF Pro Text';
    font-weight: bold;
    color: #000000;
}
.p-texts p .ptext-red {
    font-family: 'SF Pro Text';
    font-weight: bold;
    color: #D0021B;
    margin-left: 15px;
}
.ada_text {
    width: 100%;
    display: block;
    position: relative;
    max-width: 941px;
    margin: 0;
}
.ada_text h6 {
    font-family: 'SF Pro Text';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.25px;
    margin: 0 0 19px 0;
    color: #4A4A4A;
}
.ada_text p {
    font-family: 'SF Pro Text';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.23px;
    margin-bottom: 0;
    color: #9B9B9B;
}
.ada_text p .p-bold {
    font-weight: 500;
    color: #4A4A4A;
}

@media screen and (min-width:1024px){
}
@media screen and (max-width:1023px){
    .signal_body {
        padding: 69px 0;
    }
    .signal_body h1{
        font-size: 34px;
    }
    .signal_blo{
        margin-top: 48px;
    }
    .m_show2 {
        display: none;
    }
    .bon_block li .main-listB .text-blocks h6 {
        font-size: 18px;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 170px;
        display: inline-block;
    }
    .PResult-block{
        padding: 100px 50px;
    }
    .p-texts p .calc-block {
        margin:0 18px;
    }
    
}
@media screen and (max-width:767px){
    .signal_body {
        padding: 69px 0;
    }
    .signal_body h1{
        font-size: 34px;
    }
    .signal_blo{
        margin-top: 48px;
    }
    .tabs-ul .nav-item a span{
        display: none;
    }
    .tabs-ul .nav-item a{
        height: 35px;
        font-size: 14px;
        padding: 3px 5px;
    }
    .signal-block{
        margin: 50px auto 0;
        max-height:100%; 
    }
    .PResult-block{
        max-height: 100%;
        margin: 50px auto 71px;
        padding: 0px;
    }
    .sTabs-ul li{
        height: 36px;
        margin: 0 1.8%;
        max-width: 17%;
    }
    .sTabs-ul li label{
        padding: 10px;
        height: 36px;
    }
    .dat-block {
        background-color: #FFFFFF;
        padding: 24.5px 20px;
        border-radius: 7px;
        margin: 50px auto 74px;
    }
    .dat-block p, .dat-block p .text-block {
        font-size: 16px;
    }
    .m_show2 {
        display: inline-block;
    }
    .p-texts p .calc-block .input-invest {
        height: 100%;
        line-height: 18px;
    }
    .p-texts p .calc-block {
        height: 30px;
        margin:0 15px;
    }
    .p-texts p .ptext-red {
        /* display: block; */
        margin-left: 10px;
    }
    .ada_text h6 {
        font-size: 11px;
        line-height: 13px;
    }
    .ada_text p {
        font-size: 12px;
        letter-spacing: -0.2px;
        line-height: 15px;
    }
    .main-blcks .bon_block li .main-listB .num-block, 
    .main-blcks .bon_block li .main-listB .btns-blxck .btn-r_arrow {
        display: none;
    }
    .main-blcks .bon_block li .main-listB .img-blocs {
        margin-right: 14px;
        height: 30px;
        width: 30px;
    }
    .main-blcks .bon_block li .main-listB .img-blocs img {
        height: 30px;
        width: 30px;
        border-radius: 15px;
    }
    .main-blcks .bon_block li .main-listB .text-blocks h6 {
        font-size: 16px;
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 215px;
        display: inline-block;
    }
    .main-blcks .bon_block li .main-listB .text-blocks p {
        font-size: 12px;
        line-height: 15px;
    }
    .main-blcks .bon_block li .main-listB .btns-blxck2 .btn-status{
        display: none !important;
    }
    .main-blcks .bon_block li{
        margin-bottom: 50px;
    }
    .main-blcks .bon_block li .main-listB .btns-blxck .active-icon{
        display: none;
    }
    .main-blcks .bon_block li .main-listB .btns-blxck2 {
        width: 5%;
    }
    .main-blcks .bon_block li .main-listB .btns-blxck2 .btn-r_arrow {
        display: inline-block !important;
    }
    .main-blcks .bon_block li .main-listB .btns-blxck .btn-status {
        max-width: 121px;
        min-width: 121px;
        font-size: 13px;
        font-family: "SF Pro Text";
        /* font-weight: 500; */
        margin-right: 0;
        height: 30px;
        padding: 5px 10px;
        border-radius: 5px;
    }
    .m_hidden {
        display: none;
    }
    .signal-block .bon_block{
        max-height: 100%;
    }
    
}
@media screen and (max-width:375px){
    .main-blcks .bon_block li .main-listB .text-blocks h6 {
        font-size: 16px;
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 170px;
        display: inline-block;
    }
}
@media screen and (max-width:320px){
    .sTabs-ul li {
        max-width: 49px;
    }
    .p-texts p {
        font-size: 14px;
    }
    .p-texts p .calc-block {
        margin:0 15px;
    }
    .ada_text h6 {
        font-size: 10px;
        line-height: 13px;
    }
    .main-blcks .bon_block li .main-listB .text-blocks h6 {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
        display: inline-block;
    }
    .main-blcks .bon_block li .main-listB .text-blocks p{
        font-size: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
        display: inline-block;
    }
    .tabs-ul .nav-item a{
        font-size: 12px;
    }
    .signal-block2 .bon_block li .main-listB .text-blocks h6{
        display: block;
        max-width: 200px;
    }
    .signal-block2 .bon_block li .main-listB .text-blocks p{
        /* display: block; */
        max-width: 200px;
    }
}