.loader_block{
    width: 100%;
    height: 45vh;
    display: inline-block;
    position: relative;
    padding: 55px 0;
}
.loader_block img{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width:425px){
    .loader_block img{
        width: 75px;
        height: 75px;
    }
}