.bon_body {
    padding: 84px 0;
}

.bon_body h1 {
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
    display: block;
    position: relative;
}

.main-bloc {
    width: 100%;
    display: block;
    position: relative;
    margin: 90px 0 0;
}


/* filter */

.filters-block {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 50px;
}

.serch_input {
    width: 100%;
    display: inline-block;
    position: relative;
    height: 60px;
    background-color: #FFFFFF;
    border-radius: 7px;
    border: 0;
    padding: 21px 56px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 15px;
    color: #000000;
    margin-right: 22px;
}

.serch_input:focus,
.serch_input:hover {
    outline: 0;
}

.serch_input::placeholder {
    color: #8C96A9;
}

.serch_input:disabled {
    opacity: 0.8;
    cursor: no-drop;
}

.search-form {
    position: relative;
    width: 100%;
    margin-right: 20px;
}

.search-btn {
    position: absolute;
    top: 0px;
    left: 0;
    background-color: transparent;
    border: none;
    padding: 18px;
}
.search-btn img { width: 20px;}

.btn_addCryp {
    width: 55%;
    height: 60px;
    display: inline-block;
    position: relative;
    background: #FFFFFF url('../../assets/image/add-buyicons.svg') no-repeat 88% 55%;
    border: 0;
    border-radius: 7px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 15px;
    color: #000000;
    padding: 22px 38px;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
}

.btn_addCryp:hover,
.btn_addCryp:focus {
    outline: 0;
}


/* filter over */


/* bl */

.main-blcks {
    width: 100%;
    display: inline-block;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.20);
    padding: 100px 0px;
    max-height: 595px;
    overflow: hidden;
}

.bon_block {
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;
    height: 100%;
    max-height: 395px;
    overflow: hidden;
    overflow-y: auto;
    display: block;
    position: relative;
    padding-right: 5px;
    padding: 0 145px;
}

.bon_block .notFound {
    background-color: transparent;
    padding: 20px 35px
}

.bon_block li {
    list-style: none;
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 65px;
}

.bon_block li:last-child {
    margin-bottom: 0;
}

.bon_block li .main-listB {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.bon_block li .main-listB .num-block {
    display: inline-block;
    position: relative;
    min-width: 60px;
    max-width: 65px;
    margin-right: 29px;
    top: 5px;
}

.bon_block li .main-listB .num-block p {
    font-family: "SF Pro Text";
    font-size: 26px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 0;
    text-align: right;
    color: #000000;
}

.bon_block li .main-listB .img-blocs {
    width: 50px;
    height: 50px;
    display: inline-block;
    position: relative;
    margin-right: 44px;
}

.bon_block li .main-listB .img-blocs img {
    width: 50px;
    border-radius: 25px;
}

.bon_block li .main-listB .text-blocks {
    width: 100%;
    display: inline-block;
    position: relative;
}

.bon_block li .main-listB .text-blocks h6 {
    font-family: "SF Pro Text";
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: -0.12px;
    margin: 0;
    margin-bottom: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    color: #000000;
}

.bon_block li .main-listB .text-blocks h6 .text-had {
    display: inline-block;
    white-space: nowrap;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bon_block li .main-listB .text-blocks h6 .cur-tex {
    font-family: "SF Pro Text";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.31px;
    color: #666666;
    margin-left: 8px;
}

.bon_block li .main-listB .text-blocks h6 .cur-tex .dot-rtex {
    width: 3px;
    height: 3px;
    display: inline-block;
    position: relative;
    border-radius: 5px;
    background-color: #9B9B9B;
    margin-right: 8px;
    top: -2px;
}

.bon_block li .main-listB .text-blocks p {
    font-family: "SF Pro Text";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.09px;
    color: #666666;
    margin-left: 10px;
    margin: 0;
}

.bon_block li .main-listB .text-blocks p .stok-resu {
    line-height: 22px;
    letter-spacing: -0.47px;
    margin-left: 10px;
}

.bon_block li .main-listB .text-blocks p .stok-resu.red {
    color: #E70E3B;
}

.bon_block li .main-listB .text-blocks p .stok-resu.green {
    color: #13B675;
}

.bon_block li .main-listB .btns-blxck {
    width: 50%;
    display: flex;
    align-items: center;
    position: relative;
}

.bon_block li .main-listB .btns-blxck .btn-status {
    width: 100%;
    max-width: 200px;
    min-width: 200px;
    height: 40px;
    display: inline-block;
    position: relative;
    padding: 10px 15px;
    border: 0;
    border-radius: 7px;
    text-align: center;
    font-family: "SF Pro Text";
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-right: 25px;
}

.bon_block li .main-listB .btns-blxck .btn-status:hover,
.bon_block li .main-listB .btns-blxck .btn-status:focus {
    outline: 0;
    box-shadow: none;
}

.bon_block li .main-listB .btns-blxck .btn-status[name="buy"],
.bon_block li .main-listB .btns-blxck .btn-status[name="str-buy"] {
    background-color: #13B675;
}

.bon_block li .main-listB .btns-blxck .btn-status[name="sell"],
.bon_block li .main-listB .btns-blxck .btn-status[name="str-sell"] {
    background-color: #D0555A;
}

.bon_block li .main-listB .btns-blxck .btn-status[name="hold"] {
    background-color: #3B7CFF;
}

.bon_block li .main-listB .btns-blxck .btn-status[name="paid"] {
    background-color: #000000;
}

.bon_block li .main-listB .btns-blxck .active-icon {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
    position: absolute;
    top: 17px;
    left: -37px;
    background: #D0555A;
}

.bon_block li .main-listB .btns-blxck .btn-r_arrow {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
}

.btn-crypto {
    width: 100%;
    max-width: 447px;
    min-width: 447px;
    height: 60px;
    background: #FFFFFF url('../../assets/image/add-buyicons.svg') no-repeat 94% 55%;
    background-size: 33px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
    padding: 18px 25px;
    display: block;
    margin: 82px auto 0;
    text-align: center;
    font-family: "SF Pro Text";
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    color: #000000;
    border: 0;
    border-radius: 30px;
    text-decoration: none;
}


/* bl over */


/* addCrypto Modal */

.block-cc {
    width: 100%;
    display: inline-block;
    position: relative;
}

.block-cc h6 {
    font-family: "SF Pro Text";
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 0;
    margin: 0;
    text-align: left;
    color: #000000;
    display: flex;
    align-items: center;
    width: 100%;
}

.block-cc h6 .main-texts {
    width: 100%;
}

.block-cc h6 .search-bl {
    float: right;
}

.block-cc h6 .search-bl .btn-searcs {
    padding: 0;
    background: 0;
    border: 0;
}

.block-cc h6 .inputs_searc {
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    background: #F2F2F2;
}

.block-cc h6 .inputs_searc .search-input {
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 5px 15px;
    background: #FFFFFF;
    height: 45px;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    font-family: "SF Pro Text";
}

.block-cc h6 .inputs_searc .btn-cancel2 {
    font-size: 15px;
    font-weight: 400;
    color: #D0555A;
    font-family: "SF Pro Text";
    background: transparent;
    border: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    margin-left: 12px;
}

.con_block {
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 50px 0 70px;
}

.con_block .divi_block {
    width: 50%;
    float: left;
}

.curr-block {
    width: 100%;
    display: inline-block;
    position: relative;
    padding-right: 15px;
}

.curr-block h5 {
    font-size: 17px;
    font-weight: bold;
    color: #000000;
    font-family: "SF Pro Text";
    display: inline-block;
    position: relative;
    margin: 0 0 12px 0;
}

.curr-block .cur_ul {
    padding-left: 0;
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 15px 10px;
    max-height: 300px;
    min-height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.curr-block .cur_ul li {
    width: 100%;
    display: block;
    position: relative;
    list-style: none;
}

.curr-block .cur_ul li p {
    width: 100%;
    display: block;
    margin: 0;
    padding: 10px 5px;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    font-family: "SF Pro Text";
}

.curr-block .cur_ul li.no-data p {
    width: 100%;
    display: block;
    margin: 0;
    padding: 10px 5px;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
    font-family: "SF Pro Text";
    text-align: center;
    opacity: 0.5;
}

.curr-block .cur_ul li p .check-btn {
    float: right;
}

.curr-block .cur_ul li p .check-btn input[type="checkbox"] {
    display: none;
}

.curr-block .cur_ul li p .check-btn .checkbox-d {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    margin: 0;
    background: transparent url('../../assets/image/uncheck_icon.svg') no-repeat;
    background-size: 20px;
    cursor: pointer;
}

.curr-block .cur_ul li p .check-btn input[type="checkbox"].active~.checkbox-d {
    background: transparent url('../../assets/image/checked_icon.svg') no-repeat;
    background-size: 20px;
}

.btns-blc {
    width: 100%;
    max-width: 60%;
    display: block;
    position: relative;
    margin: 0 auto;
}

.btns-blc .btn-saves {
    height: 48px;
    width: 56%;
    border-radius: 7px;
    background-color: #1a62f5;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    border: 0;
    letter-spacing: 0.5px;
    float: right;
}

.btns-blc .btn-saves:disabled {
    opacity: 0.5;
    cursor: no-drop;
}

.btns-blc .btn-saves .loader {
    width: 30px;
    height: 30px;
}

.loader {
    width: 30px;
    height: 30px;
}

.btns-blc .btn-cancels {
    height: 48px;
    width: 40%;
    border-radius: 7px;
    background-color: #000000;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    border: 0;
    float: left;
    letter-spacing: 0.5px;
}

.btns-blc .btn-saves:hover,
.btns-blc .btn-saves:focus,
.btns-blc .btn-cancels:hover,
.btns-blc .btn-cancels:focus {
    outline: 0;
}

.btn-blc {
    display: none;
}


/* addCrypto Modal over */

.btn-blc .btn-searchs {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.btn-blc .btn-addCur {
    padding: 0;
    text-decoration: none;
    display: inline-block;
    position: relative;
    background: 0;
    border: 0;
}

.input_block {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    background: #F2F2F2;
}

.input_block .input_search {
    width: 100%;
    border: 0;
    border-radius: 5px;
    padding: 5px 15px;
    background: #FFFFFF;
    height: 45px;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    font-family: "SF Pro Text";
}

.input_block .btn-cancel {
    font-size: 15px;
    font-weight: 400;
    color: #D0555A;
    font-family: "SF Pro Text";
    background: transparent;
    border: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    width: auto;
}


/* appModal */

.appModal .modal-lg {
    max-width: 1000px;
}

.appModal .modal-content {
    min-height: 735px;
    border-radius: 15px;
    background-color: #F2F2F2;
    padding: 59px 65px 0 65px;
}

.appModal .modal-body {
    padding: 0;
}


/* addModal over */

@media screen and (min-width:768px) and (max-width:1023px) {
    .bon_block {
        padding: 0px 65px;
    }
    .main-bloc .filters-block .btn_addCryp {
        font-size: 16px;
        padding: 22px 22px;
        background-position: 93% 50%;
        background-size: 33px;
    }
    .appModal .modal-lg {
        max-width: 700px;
    }
    .appModal .modal-content {
        padding: 59px 30px 0 30px;
        min-height: 673px;
    }
    .main-blcks .bon_block li .main-listB .text-blocks h6 {
        display: flex !important;
        align-items: center;
    }
}

@media screen and (max-width:767px) {
    .appModal .modal-content {
        padding: 30px 20px 0 20px;
    }
    .bon_body {
        padding: 43px 0;
    }
    .bon_body h1 {
        font-family: 'Montserrat', sans-serif;
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 68px;
        text-align: left;
    }
    .main-bloc {
        display: none;
    }
    .btn-blc {
        display: inline-block;
        float: right;
    }
    .btn-crypto {
        display: none;
    }
    .main-blcks {
        padding: 0;
        margin: 0;
        max-height: 100%;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0;
    }
    .main-blcks .bon_block {
        padding-right: 0;
        padding: 0;
        max-height: 100%;
    }
    .main-blcks .bon_block li {
        margin-bottom: 50px;
    }
    .main-blcks .bon_block li .main-listB .num-block,
    .main-blcks .bon_block li .main-listB .btns-blxck .btn-r_arrow {
        display: none;
    }
    .main-blcks .bon_block li .main-listB .img-blocs {
        margin-right: 14px;
        height: 30px;
        width: 30px;
    }
    .main-blcks .bon_block li .main-listB .img-blocs img {
        height: 30px;
        width: 30px;
        border-radius: 15px;
    }
    .main-blcks .bon_block li .main-listB .text-blocks h6 {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
        display: flex !important;
        align-items: center;
    }
    .main-blcks .bon_block li .main-listB .text-blocks h6 .text-had {
        display: unset;
    }
    .main-blcks .bon_block li .main-listB .text-blocks h6 .cur-tex {
        font-size: 10px;
        line-height: 19px;
    }
    .main-blcks .bon_block li .main-listB .text-blocks p {
        font-size: 12px;
        line-height: 15px;
    }
    .main-blcks .bon_block li .main-listB .btns-blxck .btn-status {
        max-width: 121px;
        min-width: 121px;
        font-size: 13px;
        font-family: 'SF Pro Text';
        margin-right: 0;
        height: 30px;
        padding: 5px 5px;
        border-radius: 5px;
    }
    .block-cc h6 {
        font-size: 25px;
    }
    .con_block .divi_block {
        width: 100%;
        float: unset;
    }
    .curr-block {
        padding-right: 0;
        margin-bottom: 20px;
    }
    .btns-blc {
        margin-bottom: 130px;
        max-width: 100%;
    }
    .search-form { position: initial;}
}

@media screen and (max-width:375px) {
    .main-blcks .bon_block li .main-listB .text-blocks h6 .text-had {
        max-width: 100px;
    }
}

@media screen and (max-width:320px) {
    .main-blcks .bon_block li .main-listB .text-blocks h6 .text-had {
        max-width: 50px;
    }
    .block-cc h6 {
        font-size: 20px;
    }
    .curr-block h5 {
        font-size: 15px;
    }
}