.hiw_body{
    padding: 84px 0;
}
.hiw_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.hwi_blo{
    width: 100%;
    display: block;
    position: relative;
    margin-top: 97px;
    /* max-height: 700px; */
    /* background-color: #ffffff; */
    /* border-radius: 15px;
    padding: 85.5px 94px; 
    box-shadow: 0 5px 13px rgba(64,85,196,0.14); */
}
.hwi_steps{
    width: 100%;
    display: inline-block;
    position: relative;
    /* margin-bottom: 200px; */
}
/* .hwi_steps .hwi_desktop{
    width: 100%;
    display: block;
    position: relative;
    align-items: center;
}
.hwi_steps .hwi_mobile{
    display: none;
}  
.hwi_steps .step_block{
    width: 100%;
    display: inline-block;
    position: relative;
}
.hwi_steps .step_block .step_ui{
    width: 100%;
    max-width: 287px;
    max-height: 740px;
    float: right;
    margin-right: 100px;
}
.hwi_steps .step_block:nth-child(2) .step_ui{
    float: left;
    margin-left: 100px;
    margin-right: 0;
}
.hwi_steps .step_block .step_ui .step_text{
    width:100%;
    display: inline-block;
    position: relative;
    margin-bottom: 30px;
}
.hwi_steps .step_block .step_ui .step_text h6{
    width: 100%;
    text-align: center;
    font-family: 'SF Pro Text';
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 18px;
} */
.hwi_steps .step_block .step_ui .iphonex-exchange{
    margin: 0;
}
.hwi_steps .step_block .step_ui .mobile-ui2:hover,
.hwi_steps .step_block .step_ui .mobile-uiAlert:hover,
.hwi_steps .step_block .step_ui .iphonex-exchange:hover,
.hwi_blocks_m .mobile-ui2:hover,
.hwi_blocks_m .mobile-uiAlert:hover,
.hwi_blocks_m .mobile-Etherium:hover{
    transform: scale(0.7);
}
/* .hwi_steps .step_block .step_ui .step_text p{
    width: 100%;
    text-align: justify;
    font-family: 'SF Pro Text';
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 23px;
    color: #111111;
    margin-bottom: 0px;
}
.hwi_steps .step_element{
    position: absolute;
    height: 65%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.hwi_steps .step_element::after{
    content: '';
    position: absolute;
    width:2px;
    height:100%;
    border: 2px dashed #E40D3A;
    z-index: -2;
    left: -2px;
}
.hwi_steps .step_element .step_round::after{
    content: '';
    box-sizing: border-box;
    height: 84px;
    width: 84px;
    display: block;
    border: 2px dashed #E40D3A;
    background-color: #F2F2F2;
    border-radius: 50%;
    
    z-index: -1;
    position: absolute;
    top:-7px;
    left: -7px;
}
.hwi_steps .step_element .step_round{
    box-sizing: border-box;
    height: 70px;
    width: 70px;
    border:0;
    display: block;
    border-radius: 50%;
     display: inline-block; 
    position: absolute;
    background: radial-gradient(circle, #E40D3A 0%, #92001F 100%);
    color: #FFFFFF;
    font-family: 'SF Pro Text';
    font-size: 37px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
    padding: 12px;
    z-index: 1; 
}
.hwi_steps .step_element .step_round.step1{
    top:-35px;
    left:-35px;
}
.hwi_steps .step_element .step_round.step2{
    top:50%;
    left:-35px;
}
.hwi_steps .step_element .step_round.step3{
    bottom:-35px;
    left:-35px;
} */
.owl_signals{
    width: 100%;
    max-width: 461px;
    max-height: 275px;
    display: block;
    margin: 0 auto;
    background-color: #FFFFFF;
    border:0;
    padding: 60px;
    border-radius: 7px;
    box-shadow: 0 7px 10px 0 rgba(0,0,0,0.13), 0 10px 12px 0 rgba(0,0,0,0.18); 
}
.owl_signals .owl-item .hwi_blocks_n{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
}
.owl_signals .owl-item .hwi_blocks_n img{
    width: unset;
    height: unset;
}
.owl_signals .owl-item .hwi_blocks_n .hw-content{
    margin-left: 15px; 
}
.owl_signals .owl-item .hwi_blocks_n .hw-content h6{
    font-family:'SF Pro Text';
    font-weight: 900;
    font-size: 20px;
    color: #000000;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 5px;
    text-align: left;
}
.owl_signals .owl-item .hwi_blocks_n .hw-content p{
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    color: #5E7785;
    letter-spacing: 0;
    margin-bottom: 0;
}
.owl_signals .owl-dots{
    margin-top: 60px !important;
}
.owl_signals .owl-dots .owl-dot{
    background: transparent;
    border:0;
}
.owl_signals .owl-dots .owl-dot span{
    box-sizing: border-box;
    height: 15px;
    width: 15px;
    border: 1px solid #000000;
    background-color: #FFFFFF;
    border-radius: 50%;
    margin: 0 5px 0 0;
    position: relative;
}
.owl_signals .owl-dots .owl-dot:hover span{
    background-color: transparent !important;
    border-color:#000000;
    position: relative;
    display: inline-block;
}
.owl_signals .owl-dots .owl-dot:hover span::after{
    content:'';
    height: 11px;
    width: 11px;
    display: inline-block;
    border-radius: 50%;
    border:0;
    background: radial-gradient(circle, rgb(206, 206, 206) 0%, rgb(15, 15, 15) 100%);
    position: absolute;
    left: 1px;
    top: 1px;
}
.owl_signals .owl-dots .owl-dot:last-child span{
    margin: 0;
}
.owl_signals .owl-dots .owl-dot.active span{
    background-color: transparent !important;
    border-color:#E40D3A;
    position: relative;
    display: inline-block;
}
.owl_signals .owl-dots .owl-dot.active span::after{
    content:'';
    height: 11px;
    width: 11px;
    display: inline-block;
    border-radius: 50%;
    border:0;
    background: radial-gradient(circle, #E40D3A 0%, #92001F 100%);
    position: absolute;
    left: 1px;
    top: 1px;
}
.owl_signals .owl-dots.disabled{
    display: block !important;
}
/* mobile design */
.hwi_steps .owl_hwi{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 97px;
}
.hwi_steps .owl_hwi .item{
    padding-top: 0px;
    /* height: 765px; */
    height:530px;
}
.hwi_steps .owl_hwi .hwi_blocks_m{
    width: 100%;
    max-width: 848px;
    /* height: 682px; */
    height:480px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    position: relative;
    background-color: transparent;
    text-align: center;
    border: 2px dashed #E40D3A;
    border-radius: 35px;
    padding-bottom: 35px;
    z-index: 0;
    padding: 15px;
}
.hwi_blocks_m .mobile_step{
    box-sizing: border-box;
    height: 70px;
    width: 70px;
    border:0;
    display: inline-block;
    border-radius: 0 50% 50% 0;
    position: absolute;
    background: radial-gradient(circle, #E40D3A 0%, #92001F 100%);
    color: #FFFFFF;
    font-family: 'SF Pro Text';
    font-size: 37px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
    padding: 12px;
    top: 44%;
    left: 5px;
    margin: 0 auto;
}
.hwi_blocks_m .mobile_step::after{
    content: '';
    box-sizing: border-box;
    height: 84px;
    width: 84px;
    display: block;
    border: 2px dashed #E40D3A;
    background-color: #F2F2F2;
    border-radius: 0 50% 50% 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.hwi_blocks_m .hw_m_content{
    width: 100%;
    /* height: 120px; */
    display: inline-block;
    position: relative;
    /* margin-bottom: 60px; */
    max-width: 330px;
    order: 2;
}
.iphonex-exchange{
    display: inline-block;
    position: relative;
    background: url('../../assets/image/iphonex-exchange.svg') no-repeat -10px -10px;
    /* background-size: 450px 758px; */
    height: 590px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.hwi_blocks_m .hw_m_content h6{
    font-family:'SF Pro Text';
    font-weight: 900;
    font-size: 20px;
    color: #000000;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 15px;
    text-align: left;
}
.hwi_blocks_m .hw_m_content p{
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    color: #333333;
    letter-spacing: 0;
    margin-bottom: 0;
}
.hwi_steps .owl_hwi .hwi_blocks_m .mobile-uiAlert,
.hwi_steps .owl_hwi .hwi_blocks_m .iphonex-exchange,
.hwi_steps .owl_hwi .hwi_blocks_m .mobile-ui2{
    box-shadow: unset;
    margin-left: 72px;
    margin-right: 40px;
    transform: scale(0.7);
    image-rendering: pixelated;
}
.hwi_steps .owl_hwi .owl-dots .owl-dot{
    background: transparent;
    border:0;
}
.hwi_steps .owl_hwi .owl-dots .owl-dot span{
    box-sizing: border-box;
    height: 15px;
    width: 15px;
    border: 1px solid #000000;
    background-color:transparent;
    border-radius: 50%;
    margin: 0 5px 0 0;
    position: relative;
}
.hwi_steps .owl_hwi .owl-dots .owl-dot:hover span{
    background-color: transparent !important;
    border-color:#000000;
    position: relative;
    display: inline-block;
}
.hwi_steps .owl_hwi .owl-dots .owl-dot:hover span::after{
    content:'';
    height: 11px;
    width: 11px;
    display: inline-block;
    border-radius: 50%;
    border:0;
    background: radial-gradient(circle, rgb(206, 206, 206) 0%, rgb(15, 15, 15) 100%);
    position: absolute;
    left: 1px;
    top: 1px;
}
.hwi_steps .owl_hwi .owl-dots .owl-dot:last-child span{
    margin: 0;
}
.hwi_steps .owl_hwi .owl-dots .owl-dot.active span{
    background-color: transparent !important;
    border-color:#E40D3A;
    position: relative;
    display: inline-block;
}
.hwi_steps .owl_hwi .owl-dots .owl-dot.active span::after{
    content:'';
    height: 11px;
    width: 11px;
    display: inline-block;
    border-radius: 50%;
    border:0;
    background: radial-gradient(circle, #E40D3A 0%, #92001F 100%);
    position: absolute;
    left: 1px;
    top: 1px;
}
/* owl nav */
.hwi_steps .owl_hwi .owl-nav{
    margin: 0;
    position: absolute;
    top: 43%;
    transform: translate(0px, -41%);
    width: 100%;
    height: 84px;
    display: inline-block;
}
.hwi_steps .owl_hwi .owl-nav .owl-prev,
.hwi_steps .owl_hwi .owl-nav .owl-next{
    width: 84px;
    height: 84px;
    background-color: #F2F2F2;
    font-family: 'SF Pro Text';
    font-size: 50px;
    border: 2px dashed #E40D3A;
    display: inline-block;
    color: #FFFFFF;
    line-height: 50px;
    opacity: 0.5;
    margin: 0;
    position: absolute;
}
.hwi_steps .owl_hwi .owl-nav .owl-prev{
    border-radius: 50% 0 0 50%;
}
.hwi_steps .owl_hwi .owl-nav .owl-next{
    border-radius: 0 50% 50% 0;
}
.hwi_steps .owl_hwi .owl-nav .owl-prev:hover,
.hwi_steps .owl_hwi .owl-nav .owl-next:hover{
    opacity: 1;
}
.hwi_steps .owl_hwi .owl-nav .owl-prev::after,
.hwi_steps .owl_hwi .owl-nav .owl-next::after{
    content: '';
    box-sizing: border-box;
    height: 70px;
    width: 70px;
    display: block;
    /* border: 2px dashed #E40D3A; */
    background: radial-gradient(circle, #E40D3A 0%, #92001F 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}
.hwi_steps .owl_hwi .owl-nav .owl-prev::after{
    border-radius: 50% 0 0 50%;
}
.hwi_steps .owl_hwi .owl-nav .owl-next::after{
    border-radius: 0 50% 50% 0;
}
.hwi_steps .owl_hwi .owl-nav .owl-prev span,
.hwi_steps .owl_hwi .owl-nav .owl-next span{
    position: relative;
    z-index: 1;
}
.hwi_steps .owl_hwi .owl-nav .owl-prev span{
    top: -5px;
    left: -2px;
}
.hwi_steps .owl_hwi .owl-nav .owl-next span{
    top: -5px;
    left: 2px;
}
.hwi_steps .owl_hwi .owl-nav .owl-prev{
    left: -6px;
}
.hwi_steps .owl_hwi .owl-nav .owl-next{
    right: -6px;
}
/* owl nav over */

/* .hwi_blo{
    width: 100%;
    display: block;
    position: relative;
    margin-top: 97px;
    max-height: 700px;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 85.5px 94px; 
    box-shadow: 0 5px 13px rgba(64,85,196,0.14);
} 
.hiw_table{
    border:0;
    border-collapse: unset;
    width: 100%;
}
.hiw_table tbody tr td{
    border:0;
    padding: 0;
}
.hiw_table tbody tr td .hwi_block{
    width:100%;
    max-width: 276px;
    display: inline-block;
    position: relative;
    margin: 0 auto 15px;
    text-align: center;
}
.hiw_table tbody tr td:last-child{
    text-align: right;
}
.hiw_table tbody tr:nth-child(2) td{
    text-align: center;
}
.hiw_table tbody tr td .hwi_block img{
    width: 55px;
    height: 55px;
    margin-bottom: 18px;
}
.hiw_table tbody tr td .hwi_block h6{
    text-align: center;
    font-size: 20px;
    font-family: 'Helvetica Neue Bold';
    font-weight: normal;
    color: #000000;
    line-height: 20px;
    letter-spacing: normal;
    margin-bottom: 20px;
}
.hiw_table tbody tr td .hwi_block p{
    text-align: center;
    font-size: 16px;
    font-family: 'Helvetica Neue Medium';
    font-weight: 400;
    color: #5E7785;
    line-height: 25px;
    letter-spacing: normal;
    margin-bottom: 0px;
}
.hiw_table tbody tr:last-child td .hwi_block{
    margin: 0 auto;
} */
@media screen and (max-width:1023px){
    .hiw_body {
        padding: 69px 0;
    }
    .hiw_body h1{
        font-size: 34px;
    }
    .hwi_blo{
        margin-top: 82px;
        padding: 0;
        background-color: transparent;
        max-height: 100%;
        box-shadow:unset;
    }
    .hwi_steps .hwi_desktop{
        display: none;
    }
    .hwi_steps .hwi_mobile{
        display: block;
    }
    .hwi_steps .owl_hwi{
        width: 100%;
        display: inline-block;
        position: relative;
        margin-bottom: 97px;
    }
    .hwi_steps .owl_hwi .item{
        padding-top: 50px;
        height: 990px;
    }
    .hwi_steps .owl_hwi .hwi_blocks_m{
        width: 100%;
        max-width: 375px;
        height: 900px;
        display: inline-block;
        position: relative;
        background-color: transparent;
        text-align: center;
        border: 2px dashed #E40D3A;
        border-radius: 35px;
        padding-bottom: 35px;
        z-index: 0;
        padding: 15px;
    }
    .hwi_blocks_m .mobile_step{
        box-sizing: border-box;
        height: 70px;
        width: 70px;
        border:0;
        display: block;
        border-radius: 50%;
        /* display: inline-block; */
        position: relative;
        background: radial-gradient(circle, #E40D3A 0%, #92001F 100%);
        color: #FFFFFF;
        font-family: 'SF Pro Text';
        font-size: 37px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 44px;
        text-align: center;
        padding: 12px;
        top: -50px;
        left: 0;
        margin: 0 auto;
    }
    .hwi_blocks_m .mobile_step::after{
        content: '';
        box-sizing: border-box;
        height: 84px;
        width: 84px;
        display: block;
        border: 2px dashed #E40D3A;
        background-color: #F2F2F2;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
    .hwi_blocks_m .hw_m_content{
        width: 100%;
        height: 120px;
        display: inline-block;
        position: relative;
        margin-bottom: 60px;
    }
    .hwi_blocks_m .hw_m_content h6{
        font-family:'SF Pro Text';
        font-weight: 900;
        font-size: 20px;
        color: #000000;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 15px;
        text-align: center;
    }
    .hwi_blocks_m .hw_m_content p{
        font-family: 'SF Pro Text';
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        color: #333333;
        letter-spacing: 0;
        margin-bottom: 0;
    }
    .hwi_steps .owl_hwi .hwi_blocks_m .mobile-uiAlert,
    .hwi_steps .owl_hwi .hwi_blocks_m .iphonex-exchange,
    .hwi_steps .owl_hwi .hwi_blocks_m .mobile-ui2{
        box-shadow: unset;
        margin-left: 0px;
        margin-right: 0px;
        transform: scale(1);
        display: inline-block;
    }
    .hwi_steps .owl_hwi .owl-nav{
        display: none;
    }
    /* .hiw_table tbody tr td .hwi_block{
        margin-bottom: 30px;
    }
    .hiw_table tbody tr:last-child td .hwi_block{
        margin-bottom: 67px;
    }
    .hiw_table tbody tr:last-child td .hwi_block{
        margin-bottom: 0;
    } */
}
@media screen and (max-width:767px){
    .hiw_body {
        padding: 69px 0;
    }
    .hiw_body h1{
        font-size: 34px;
    }
    .hwi_blo{
        margin-top: 82px;
        padding: 0;
        background-color: transparent;
        max-height: 100%;
    }
    .owl_signals{
        padding: 40px 20px;
        max-height: 365px;
    }
    .owl_signals .owl-item .hwi_blocks_n{
        display: inline-block;
    }
    .owl_signals .owl-item .hwi_blocks_n img{
        margin: 0 auto;
    }
    .owl_signals .owl-item .hwi_blocks_n .hw-content{
        margin-left: 0;
        margin-top: 20px;
    }
    .owl_signals .owl-item .hwi_blocks_n .hw-content h6,
    .owl_signals .owl-item .hwi_blocks_n .hw-content p{
        text-align: center;
    }
    /* .hiw_table tbody tr td{
        display: block;
        text-align: center;
    }
    .hiw_table tbody tr td:last-child{
        text-align: center;
    }
    .hiw_table tbody tr td .hwi_block{
        margin-bottom: 67px;
    }
    .hiw_table tbody tr:last-child td .hwi_block{
        margin-bottom: 67px;
    }
    .hiw_table tbody tr:last-child td:last-child .hwi_block{
        margin-bottom: 0;
    } */
}
@media screen and (max-width:320px){
    .hwi_blocks_m .mobile-uiAlert,
    .hwi_blocks_m .mobile-ui2,
    .hwi_blocks_m .iphonex-exchange{
        transform: scale(0.9) !important;
        left: -15px;
    }
    .hwi_steps .owl_hwi .hwi_blocks_m{
        height: 850px;
    }
}