@import url('./fonts.css');
@import url('./animation.css');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #dde0e4; 
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #969ba2; 
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

body{
    background:#F2F2F2 url('../image/BShape.svg') no-repeat 100% 0;
    color: #000;
    margin: 0;
    font-family: 'SF Pro Text', "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    padding: 0;
    transition: 0.3s ease;
}

p{
    margin: 0;
}
input[type="text"],input[type="file"],input[type="password"],input[type="email"],input[type="radio"],input[type="checkbox"],button,a,input[type="submit"],textarea:hover,
textarea:focus{
    outline: 0;
}
  
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: inherit;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow:none;
    transition: background-color 5000s ease-in-out 0s;
}

.container{
    width:100%;
    margin: 0 auto;
    max-width: 1000px;
    padding: 0;
}
.header{
    background-color:transparent;
    width: 100%;
    height: 69px;
    display: inline-block;
    position: relative;
    transition: 0.3s ease-in-out;
    /* -webkit-animation: topToDown 0.8s ease-in-out;
    -moz-animation: topToDown 0.8s ease-in-out;
    -o-animation: topToDown 0.8s ease-in-out;
    -ms-animation: topToDown 0.8s ease-in-out;
    animation: topToDown 0.8s ease-in-out; */
}
.header.sticky{
    position: sticky;
    top:0;
    left:0;
    box-shadow: 0 2px 4px rgba(0,0,0,.075);
    z-index: 999;
    background-color: #F2F2F2;
}
.header .container .text-container{
    width:100%;
    margin: auto;
    display: flex;
}
.navbar-header .toggle-btn{
    display: none;
    width: 34px;
    height: 31px;
    position: relative;
    border: 0;
    background-color: transparent;
    padding: 0;
    text-align: left;
    cursor: pointer;
}
.navbar-header .toggle-btn .icon-bar{
    display: block;
    background-color: #E70E3B;
    width: 30px;
    height: 4.8px;
    border-radius: 100px;
    margin-bottom: 4px;
}
.navbar-header .toggle-btn .icon-bar:nth-child(2){
    width: 18px;
    top: -9px;
    margin-bottom: 4px;
}
.navbar-header .toggle-btn .icon-bar:nth-child(3){
    width: 22.8px;
    margin-bottom: 0;
}
.navbar-header .btn_login{
    float: right;
    height: 30px;
    width: 115px;
    border-radius: 60px;
    background: linear-gradient(270deg, #BE0027 0%, #E70E3B 100%);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
    padding: 9px 0;
    opacity: 1;
    color: #FFFFFF;
    font-family: 'SF Pro Text';
    font-size: 14px;
    font-weight: 900;
    line-height: 10px;
    text-align: center;
    cursor: pointer;
    border:0;
}
.navbar-header .toggle-btn:hover, .navbar-header .toggle-btn:focus,
.navbar-header .btn_login:hover,.navbar-header .btn_login:focus{
    outline: 0;
}
.navbar-header{
    width: 100%;
    max-width: 256px;
}
.navbar-header .navbar_logo{
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.06px;
    color: #1F1F1F;
    display: flex;
    align-items: center;
    padding: 13px 0;
    text-decoration: none;
    transform:perspective(1px) translateZ(0);
}
.navbar-header .navbar_logo:hover{
    -webkit-animation: buzz-out .75s linear 1;
    -moz-animation: buzz-out .75s linear 1;
    -o-animation: buzz-out .75s linear 1;
    -ms-animation: buzz-out .75s linear 1;
    animation: buzz-out .75s linear 1;
}
.navbar-header .navbar_logo img{
    margin-right: 8px;
}
.navbar-header .navbar_logo:hover,
.navbar-header .navbar_logo:focus{
    outline: 0;
    box-shadow: none;
    text-decoration: none;
}
.menuLink{
    width:calc(100% - 265px);
    float: right;
    display: block;
    position: relative;
    margin-bottom: 0;
    margin-left: 50px;
    transition: 0.3s linear;
}
.menuLink .logo_body{
    display: none;
}
.menuLink .logo_body .logo_block{
    display: flex;
    flex-direction: column;
}
.menuLink .logo_body .logo_block img{
    width: 55px;
    height: 55px;
    margin: auto
}
.menuLink .logo_body .logo_block button{
    margin: 0 auto;
    margin-top: 25px;
    border:0;
}
.menuLink li{
    list-style: none;
    /* padding-left: 12px; */
    transition: 0.3s linear;
    float: left;
}
.menuLink li:nth-child(2){
    padding-left: 0;
}
.menuLink li a{
    padding: 26.5px 9px;
    display: inline-block;
    position: relative;
    opacity: 0.7;
    color: #111111;
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    transition: 0.3s linear;
}
.menuLink li a:hover{
    opacity: 1;
    font-weight: 900;
}
.menuLink li a.active{
    font-weight: 900;
    opacity: 1;
}
.menuLink li a:hover::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #111111;
    display: inline-block;
}
.menuLink li a.active::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #111111;
    display: inline-block;
}
.menuLink li.drop:hover .drop_menu{
    display: inline-block;
    border-radius: 20px;
    height: 310px;
    transition: 0.3s linear;
}
.menuLink li .drop_menu{
    width: 200px;
    min-width: 160px;
    height: 0;
    display: none;
    position: absolute;
    top: 60px;
    right: -60px;
    z-index: 5;
    padding: 10px 5px;
    background-color: #fff;
    box-shadow: 0 5px 8px rgba(0,0,0,0.3);
    border-radius: 0px;
    z-index: 999;
    transition: 0.3s linear;
}
.menuLink li .drop_menu::before{
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    border-bottom:10px solid #FFFFFF;
    border-left:10px solid transparent;
    border-right:10px solid transparent;
    transform: translate(-50%, 0);
}
.menuLink li .drop_menu li{
    width: 100%;
    display: block;
    list-style: none;
    padding: 0;
}
.menuLink li .drop_menu li a{
    width: 100%;
    text-align: left;
    padding: 10px 5px;
    display: inline-block;
    position: relative;
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-size: 14px;
    opacity: 0.7;
    color: #111111;
    transition: 0.3s linear;
}
.menuLink li .drop_menu li a:hover{
    opacity: 1;
    font-weight: 900;
    padding-left: 15px;
}
.menuLink li .drop_menu li a:hover::before{
    display: none;
}
.menuLink li .drop_menu li a.active{
    font-weight: 900;
    opacity: 1;
}
.menuLink li.drop ul li a.active::before{
    display: none;
}
.btn_login{
    height: 30px;
    width: 115px;
    border-radius: 60px;
    background: linear-gradient(270deg, #BE0027 0%, #E70E3B 100%);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
    padding: 8px 0;
    opacity: 1;
    color: #FFFFFF;
    font-family: 'SF Pro Text';
    font-size: 14px;
    font-weight: 900;
    line-height: 13px;
    text-align: center;
    margin-top: 18px;
    cursor: pointer;
    border:0;
    position: relative;
}
.btn_login:hover{
    -webkit-animation: pulse_animate 0.8s ease 5;
    -moz-animation: pulse_animate 0.8s ease 5;
    -o-animation: pulse_animate 0.8s ease 5;
    -ms-animation: pulse_animate 0.8s ease 5;
    animation: pulse_animate 0.8s ease 5;
}
.btn_login:hover,.btn_login:focus{
    outline: 0;
}
.background_overlay{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0.73);
    z-index: 99;
    display: none;
}
.background_overlay.active{
    display: inline-block;
}
.navbar-header .btn_login{
    display: none;
}
.btn_blocks2 li{
    list-style: none;
}
/* buy bitcoin */
.BuyBitcoin_body{
    padding: 136px 0;
}
/* rightToLeft */
.bitcoin_block {
    width: calc(100% - 290px);
    float: left;
    display: block;
    position: relative;
    /* -webkit-animation: leftToRight 0.8s ease-in-out;
    -moz-animation: leftToRight 0.8s ease-in-out;
    -o-animation: leftToRight 0.8s ease-in-out;
    -ms-animation: leftToRight 0.8s ease-in-out;
    animation: leftToRight 0.8s ease-in-out; */
}
.bitcoin_block h1 {
    color: #000;
    font-family: 'SF Pro Text';
    font-size: 44px;
    font-weight: 900;
    line-height: 53px;
    margin-bottom: 25px;
    margin-top: 20px;
}
.bitcoin_block p {
    font-size: 24px;
    color: #000;
    font-family: 'SF Pro Text';
    line-height: 36px;
    font-weight: 400;
    margin-bottom: 25px;
}
.app-storeBtn {
    height: 70px;
    width: 237px;
    display: inline-block;
    position: relative;
    background-color: #000000;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    border: 0;
    margin-top: 90px;
    transition: 0.3s linear;
    margin-right: 20px;
    cursor: pointer;
    padding-left: 5px;
}

.get-premium-btn {
    height: 70px;
    width: 237px;
    display: inline-block;
    position: relative;
    background-color: #000000;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    border: 0;
    margin-top: 20px;
    margin-left: 120px;
    transition: 0.3s linear;
    margin-right: 20px;
    cursor: pointer;
    padding-left: 5px;
}

.app-storeBtn .apple-iconBig {
    width: 49px;
    height: 50px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    float: left;
    background: url('../../assets/image/5images.svg') no-repeat -87px 0px;
    background-size: 134px;
}
.play-storeBtn {
    height: 70px;
    width: 237px;
    display: inline-block;
    position: relative;
    background-color: #000;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    border: 0;
    margin-top: 90px;
    transition: 0.3s linear;
    cursor: pointer;
    padding-left: 5px;
}
.play-storeBtn .play-iconBig {
    width: 49px;
    height: 50px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    float: left;
    background: url('../../assets/image/playStore-icon.png') no-repeat;
    background-size: 50px;
}
.get-premium-btn .get-premium-iconBig {
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;
    float: left;
    background: url('../../assets/image/arrow-right-white.svg') no-repeat;
    background-size: 40px;
}
.play-storeBtn p {
    width: calc(100% - 77px);
    display: inline-block;
    position: relative;
    color: #fff;
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
}
.play-storeBtn p span {
    font-family: 'SF Pro Text';
    font-weight: 900;
    font-size: 25px;
}
.app-storeBtn p {
    width: calc(100% - 77px);
    display: inline-block;
    position: relative;
    color: #fff;
    font-family: 'SF Pro Text';
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
}
.get-premium-btn p {
    display: inline-block;
    position: relative;
    color: #fff;
    font-family: 'SF Pro Text';
    font-size: 25px;
    font-weight: 800;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
}
.app-storeBtn p span{
    font-family: 'SF Pro Text';
    font-size: 25px;
    font-weight: 900;
}
.app-storeBtn:hover,.get-premium-btn:hover,
.play-storeBtn:hover{
    -webkit-animation: pulse_animate 0.8s ease 5;
    -moz-animation: pulse_animate 0.8s ease 5;
    -o-animation: pulse_animate 0.8s ease 5;
    -ms-animation: pulse_animate 0.8s ease 5;
    animation: pulse_animate 0.8s ease 5;
}
.button:hover,button:focus{
    outline: 0;
}
.green-color {
    color: #13B675;
}
.blue-color {
    color: #4940FB;
}
.red-color {
    color: #E70E3B;
}
.mobileUi-block {
    width: 290px;
    display: inline-block;
    position: relative;
    z-index: 1;
    /* animation: rightToLeft 0.8s ease-in-out; */
}
.mobile-ui {
    height: 592px;
    width: 287px;
    float: right;
    border-radius: 40px;
    background: url('../../assets/image/iphone-buynot.svg') no-repeat 68% 36%;
    /* background-size: 287px 593px; */
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    padding-left: 18px;
    position: relative;
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* .mobile-ui:hover{
    transform: scale(1.02);
} */

/* buy bitcoin over */
/* singalProfit*/
.bg-investing {
    height: 882px;
    padding: 150px 0 0;
    background: linear-gradient(270deg, #92001E 0%, #E70E3B 100%);
    position: relative;
    top: -16rem;
    z-index: 0;
}
.main-block {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 6rem;
}
.top-investing-block {
    display: inline-block;
    position: relative;
    /* padding: 50px 15px 35px 0px; */
    width: 100%;
    margin-top: 10rem;
}
.top-investing-block h4 {
    font-family: 'SF Pro Text';
    font-weight: 900;
    font-size: 44px;
    line-height: 53px;
    color: #fff;
    margin-bottom: 80px;
    font-weight: bold;
    text-align: center;
}
.mobile-ui2 {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-openSignal.svg') no-repeat 67.5% 36%;
    /* background-size: 300px 611px; */
    height: 590px;
    width: 287px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    /* animation: leftToRight 0.75s ease-in-out; */
}
.mobile-Etherium {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-Etherium.svg') no-repeat 67.5% 36%;
    /* background-size: 300px 611px; */
    height: 590px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    margin: 0 50.5px;
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    /* animation: centerToZoom 0.75s ease-in-out; */
}
.mobile-signalResult {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-signalResult.svg') no-repeat 67.5% 36%;
    /* background-size: 300px 611px; */
    height: 590px;
    width: 287px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    /* animation: rightToLeft 0.75s ease-in-out; */
}
/* .mobile-ui2:hover,
.mobile-Etherium:hover,
.mobile-signalResult:hover,
.mobile-uiSignal:hover,
.mobile-uiWebsite:hover,
.mobile-uiYoutube:hover,
.mobile-uiAlert:hover,
.imag_block .storngbuy:hover,
.imag_block .dontbuy:hover,
.imag_block .hold:hover{
    transform: scale(1.05);
} */
/* signalProfit over */
/* topInvest */
.bg-buyBitcoin2 {
    padding: 320px 0 0;
}
.mobile-uiSignal {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-topInvesting.svg') no-repeat -117px -63px;
    /* background-size: 450px 758px; */
    height: 590px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.bitcoin_block3 {
    width: calc(100% - 355px);
    float: right;
    display: inline-block;
    position: relative;
    top: 216px;
}
.bitcoin_block3 h3 {
    color: #000;
    font-family: "SF Pro Text";
    font-weight: 900;
    font-size: 44px;
    line-height: 53px;
    margin-bottom: 25px;
}
.bitcoin_block3 p {
    font-size: 24px;
    color: #000;
    font-family: 'SF Pro Text';
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 25px;
}
/* topInvest over */
/* bitcoinBuy */
.bg-buyBitcoin {
    padding: 136px 0;
    width: 100%;
    display: inline-block;
}
.bitcoin_block4 {
    width: calc(100% - 290px);
    display: inline-block;
    position: relative;
    top: 216px;
}
.bitcoin_block4{
    float: left;
}
.mobile-uiWebsite {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-topWebsite.svg') no-repeat -116px -63px;
    /* background-size: 450px 758px; */
    height: 590px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.bitcoin_block4 h3 {
    color: #000;
    font-family: "SF Pro Text";
    font-weight: 900;
    font-size: 44px;
    line-height: 53px;
    margin-bottom: 25px;
}
.bitcoin_block4 p {
    font-size: 24px;
    color: #000;
    font-family: 'SF Pro Text';
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 25px;
}
/* bitcoinBuy over */
/* youtube home*/
.mobile-uiYoutube {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-youtube.svg') no-repeat -117px -63px;
    /* background-size: 450px 758px; */
    height: 590px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* youtube home over */
/* alert home */
.mobile-uiAlert {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-alert.svg') no-repeat -116px -63px;
    /* background-size: 450px 758px; */
    height: 590px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* alert home over */
/* status work home */
.how-bg {
    padding: 150px 0;
    width: 100%;
}
.hadding {
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 10px 0;
}
.hadding h3 {
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    color: #000;
    line-height: 53px;
}
.image-blocks {
    width: 100%;
    display: inline-block;
    position: relative;
    padding-top: 70px;
}
.span-2, .span-3, .span-4, .span-5, .span-8 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.span-4 {
    width: 33.33333333%;
}
.span-2 {
    width: 16.66666667%;
}
.span-3 {
    width: 25%;
}
.span-5 {
    width: 41.66666667%;
}
.span-8 {
    width: 66.66666667%;
}
.imag_block .storngbuy {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-buy.svg') no-repeat -267px -259px;
    /* background-size: 456px 758px; */
    image-rendering: pixelated;
    height: 596px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    padding-left: 0px;
    margin: 0 auto;
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.imag_block .dontbuy {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-hold.svg') no-repeat -266px -259px;
    image-rendering: pixelated;
    height: 596px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    padding-left: 0px;
    margin: 0 auto;
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.imag_block .hold {
    display: inline-block;
    position: relative;
    background: url('../image/iphonex-dontbuy.svg') no-repeat -266px -259px;
    image-rendering: pixelated;
    /* background-size: 456px 758px; */
    height: 596px;
    width: 286.18px;
    border-radius: 40px;
    overflow: visible;
    box-shadow: inset 0 -18px 30px 0 rgba(0,0,0,0.02), 0 8px 28px 0 rgba(0,0,0,0.16), 0 50px 78px 0 rgba(0,0,0,0.18);
    padding-left: 0px;
    margin: 0 auto;
    transform: scale(1);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* footer */
.footer {
    padding: 150px 0 0;
    display: block;
}
.top-footer {
    width: 100%;
    padding-bottom: 25px;
    padding-top: 98px;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}
.footer-menu {
    width: 100%;
    display: inline-block;
    position: relative;
    padding-left: 0;
}
.footer-menu li {
    list-style: none;
    display: block;
}
.footer-menu li h4 {
    color: #000;
    font-size: 13px;
    font-family: 'SF Pro Text';
    margin-bottom: 20px;
    letter-spacing: 0px;
    line-height: 15px;
    font-weight: 500;
}
.footer-menu li a {
    padding: 13px 0 0;
    display: inline-block;
    position: relative;
    /* color: #9B9B9B; */
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    font-family: 'SF Pro Text';
    font-weight: 300;
    text-decoration: none;
}
.footer-menu li a:hover,.footer-menu li a:focus{
    outline: 0;

}
.footer-menu li:nth-child(2) a {
    padding-top: 0;
}
.footer-menu li p {
    /* color: #9B9B9B; */
    color: #000000;
    font-size: 12px;
    font-family: 'SF Pro Text';
    font-weight: 300;
    margin-bottom: 0px;
}
.replay2 {
    width: 100%;
    display: inline-block;
    position: relative;
}
.replay2 p {
    /* color: #9B9B9B; */
    color: #000000;
    font-size: 12px;
    line-height: 12px;
    font-family: 'SF Pro Text';
    letter-spacing: 0px;
    font-weight: 300;
    margin-bottom: 15px;
    margin-top: 10px;
}
.replay2 p.errorContact{
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    color: #D0555A;
    margin-top: 0px;
    margin-bottom: 0;
    letter-spacing: 0.5px;
}
.loginForm .errorContact{
    margin-bottom: 10px;
}
.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
    width: 335px;
    margin: 0 !important;
}
.input-group_footer{
    display: inline-block;
    height: 47px;
}
.input-group .form-control2 {
    position: relative;
    z-index: 2;
    float: left;
    width: 145%;
    margin-bottom: 0;
}
.input-group_footer .form-control2{
    color: #111111;
}
.form-control2 {
    display: block;
    width: 100% !important;
    height: 40px !important;
    padding: 6px 130px 6px 15px !important;
    line-height: 1.42857143;
    color: #959595;
    font-size: 12px;
    font-family: 'SF Pro Text';
    font-weight: 300;
    background-color: #fff;
    background-image: none;
    border: 0;
    border-radius: 444px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}
.form-control2:hover,.form-control2:focus{
    outline: 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}
.input-group .form-control2, .input-group-addon, .input-group-btn {
    display: table-cell !important;
    position: absolute !important;
    z-index: 2;
}
.input-group-btn {
    right: 4px;
    top: 3px;
    width: unset !important;
}
.btn-submit2 {
    width: 117px;
    display: inline-block;
    position: relative;
    height: 34px;
    border-radius: 444px;
    border: 0;
    background-color: #E70E3B;
    color: #fff;
    font-size: 14px;
    font-family: 'SF Pro Text';
    font-weight: 600;
    cursor: pointer;
}
.btn-submit2:hover,.btn-submit2:focus{
    outline: 0;
}
.btn-submit2:disabled{
    cursor: no-drop;
}
.bottom-footer {
    width: 100%;
    display: block;
    border-top: 1px solid #EAEAEA;
    padding: 15px 0 0;
}
.copyright-block {
    width: 100%;
    display: inline-block;
    position: relative;
}
.copyright-block p {
    font-family: 'SF Pro Text';
    font-weight: 300;
    font-size: 12px;
    /* color: #9B9B9B; */
    color: #000000;
    line-height: 14px;
    padding-top: 0px;
    margin-bottom: 10px;
}
.social-icons {
    width: 100%;
    display: inline-block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 0;
}
.social-icons li {
    list-style: none;
    float: left;
}
.social-icons li:nth-child(1) a .fb {
    width: 16.47px;
    height: 14.8px;
    display: inline-block;
    position: relative;
    background: url('../image/5images.svg') no-repeat -19px -16px;
    background-size: 100px;
    padding: 0;
}
.social-icons li:nth-child(2) a .twi {
    width: 16.47px;
    height: 12.95px;
    display: inline-block;
    position: relative;
    background: url('../image/5images.svg') no-repeat -36px -18px;
    background-size: 100px;
    padding: 0;
}
.social-icons li:nth-child(2) {
    margin: 0 25px;
}
.social-icons li:nth-child(3) a .insta {
    width: 16.47px;
    height: 14.8px;
    display: inline-block;
    position: relative;
    background: url('../image/5images.svg') no-repeat -122px -43px;
    background-size: 217px;
    padding: 0;
}
/* footer over */
.sm-hidden {
    display: none;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background: #E70E3B;
}
.owl-theme .owl-dots .owl-dot:hover,.owl-theme .owl-dots .owl-dot:focus{
    outline: 0;
}
.xs-hidden{
    display: inline-block;
}
.destop-view{
    display: none;
}
/* modal login css */
.login_body .modal-dialog{
    max-width: 600px;
}
.login_body .modal-content{
    padding: 82px 82px 45px 82px;
    max-width: 600px;
    min-height: 550px;
    border-radius: 25px 25px 16px 16px;
    background-color: #f2f2f2;
    /* margin: 15% auto 100px auto; */
}
.tabs-nav {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    background-color: #ffffff;
    overflow: hidden;
    padding: 4px;
    margin-bottom: 0;
    display: flex;
    transition: 0.3s linear;
}
.tabs-nav li {
    list-style: none;
    width: 50%;
    display: inline-block;
    border: none;
    height: 42px;
    color: #959595;
    font-family: 'SF Pro Text';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    transition: all 0.3s ease;
    text-align: center;
    padding: 9px 15px;
    cursor: pointer;
    z-index: 1;
}
.tabs-nav li.active{
    border-radius: 30px;
    background-color: transparent;
    color: #000000;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
}
.tabs-nav .bg-nav{
    border-radius: 30px;
    background-color: #f2f2f2;
    width:calc(100% / 2 - 20px);
    height:42px;
    display: inline-block;
    position: absolute;
    z-index: 0;
    /* top: 20px; */
    /* left: 20px;
    right: auto; */
    transform: translateX(0px);
    transition: transform 0.3s linear;
}
.tabs-nav.active .bg-nav{
    /* left: 20px;
    right: 0px; */
    transform: translateX(100%);
    transition: transform 0.3s linear;
}
.content-tabs {
    margin-top: 65px;
}
.form-controllog {
    width: 100%;
    height: 55px;
    display: inline-block;
    position: relative;
    background-color: #fff !important;
    border-radius: 0;
    border: 1px solid transparent;
    font-size: 20px;
    font-family: 'SF Pro Text';
    font-weight: 400;
    color: #000;
    padding: 15px 30px;
}
.border-radt {
    border-radius: 10px 10px 0 0 !important;
}
.border-radb {
    border-radius: 0px 0px 10px 10px !important;
}
.btn_login2 {
    width: 100%;
    height: 45px;
    display: inline-block;
    border: 0;
    border-radius: 30px;
    background: #3B7CFF;
    text-align: center;
    padding: 10px 15px;
    font-family: 'SF Pro Text';
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    line-height: 22px;
}
.btn-sub {
    width: 100%;
    height: 45px;
    display: inline-block;
    border: 0;
    border-radius: 30px;
    background: #5FB86C;
    text-align: center;
    padding: 10px 15px;
    font-family: 'SF Pro Text';
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 22px;
}
.forget-btn {
    width: 100%;
    display: inline-block;
    position: relative;
    font-family: 'SF Pro Text';
    font-weight: 300;
    font-size: 14px;
    color: #6d6c6c;
    margin-top: 20px;
    text-align: center;
    line-height: 16px;
    letter-spacing: -0.35px;
    background: transparent;
    border:0;
}
.btn_login2:hover,.btn_login2:focus, 
.forget-btn:hover,.forget-btn:focus,
.btn-sub:hover,.btn-sub:focus{
    outline: 0;
}
.btn-sub:disabled,.btn_login2:disabled,.btn-resetP:disabled{
    cursor: no-drop;
    opacity: 0.5;
}
.mt-100 {
    margin-top: 100px;
}
.log_body,.sign_body{
    display: none;
}
.log_body.active,.sign_body.active{
    display: block;
}
/* login modal css over */
/* fP_modal */

.fp_modal .modal-content{
    max-width: 600px;
    width: 100%;
    padding: 24px;
    border-radius: 12px;
    background-color: #f2f2f2;
}
.logOut_text_ti {
    color: #000;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    font-family: 'SF Pro Text';
}
.ForgotPwd_text_ti {
    color: #8c8b8b;
    font-size: 17px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    font-family: 'SF Pro Text';
}
.forgot_input{
    border-radius: 10px;
    margin: 15px 0;
}
.btn-resetP{
    width: 48%;
    height: 45px;
    margin: 5px;
    background-color: #D0555A;
    display: inline-block;
    border: 0;
    border-radius: 30px;
    text-align: center;
    padding: 10px 15px;
    font-family: 'SF Pro Text';
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    line-height: 22px;
    margin: 0 16px 0 0;
}
.btn-cancel{
    width: 48%;
    margin: 5px;
    background-color: #1F1F1F;
    display: inline-block;
    border: 0;
    border-radius: 30px;
    text-align: center;
    padding: 10px 15px;
    font-family: 'SF Pro Text';
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    line-height: 22px;
    margin: 0;
}
.btn-resetP:hover,.btn-resetP:focus,
.btn-cancel:hover,.btn-cancel:focus{
    outline: 0;
}
/* fP_modal over */
.form-controllog:-webkit-autofill,
.form-controllog:-webkit-autofill:hover,
.form-controllog:-webkit-autofill:focus{
    border: 0;
    -webkit-text-fill-color: #000 !important;
    -webkit-box-shadow: unset;
    transition: background-color 5000s ease-in-out 0s;
}
.form-controllog:-internal-autofill-selected{
    background-color: #fff;
}
.form-control2:-webkit-autofill,
.form-control2:-webkit-autofill:hover,
.form-control2:-webkit-autofill:focus{
    border: 0;
    -webkit-text-fill-color: #000 !important;
    -webkit-box-shadow: unset;
    transition: background-color 5000s ease-in-out 0s;
}
.forgot_btn{
    margin-top: 50px;
}
/* loader small */
.user_loader{
    width: 100%;
    display: inline-block;
    position: absolute;
    max-width: 93%;
    margin-top: 27px;
    text-align: center;
}
.user_loader img{
    width:40px;
}
.loader_user2{
    margin-top: -21px;
}
.user_loader3{
    margin-top: 5px;
}
.hadding_suc{
    width: 100%;
    display: inline-block;
    position: relative;
}
.hadding_suc h5{
    color: #000;
    font-size: 26px;
    font-family: 'SF Pro Text';
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    margin-bottom: 15px;
}
.hadding_suc p{
    color: #8c8b8b;
    font-size: 17px;
    font-family: 'SF Pro Text';
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    margin-bottom: 15px;
}
.text-green2{
    font-size: 14px;
    color: #34903c;
    font-family: 'SF Pro Text';
    font-weight: 500;
    margin: 10px 0;
    text-align: center;
}
.resend-code{
    margin: 10px 0;
    text-align: center;
    font-size: 15px;
    color: #8c8b8b;
    font-family: 'SF Pro Text';
    font-weight: 500;
}
.resend-code button{
    color: red;
    font-size: 12px;
    background: transparent;
    border:0;
    margin-left: 5px;
    text-decoration: underline;
}
.resend-code button:hover,.resend-code button:focus{
    outline: 0;
}
.btn-block3{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 65px;
}
.btn-block3 button{
    width: 48%;
}
.btn-block3 button:last-child{
    float: right;
}
.btn-block3 button:hover,.btn-block3 button:focus{
    outline: 0;
}
.btn-restpas{
    height: 45px;
    display: inline-block;
    border: 0;
    border-radius: 30px;
    background: #50ab40;
    text-align: center;
    padding: 10px 15px;
    font-family: 'SF Pro Text';
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    line-height: 22px;
}
.btn-restpas:disabled{
    opacity: 0.5;
    cursor: no-drop;
}
.btn-cancel3{
    height: 45px;
    display: inline-block;
    border: 0;
    border-radius: 30px;
    background: #1F1F1F;
    text-align: center;
    padding: 10px 15px;
    font-family: 'SF Pro Text';
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    line-height: 22px;
}
/* loader small over */
/* err & success */
.errorContact, .green-block {
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 0;
}
.errorContact {
    font-size: 15px;
    color: #d0555a;
    margin-top: 10px;
    letter-spacing: .5px;
}

/* loader csss */
.sendLoader{
    width: 25px;
    height: 25px;
}
.small_loader{
    width:15px;
    height: 15px;
}

/* logout modal css */
.logout_modal .modal-dialog {
    max-width: 750px;
}
.logout_modal .modal-content {
    max-width: 750px;
    width: 100%;
    height: 100%;
    max-height: 600px;
    background-color: #fff;
    box-shadow: 0 20px 20px rgba(0,0,0,.15);
    border-radius: 25px 25px 16px 16px;
}
.logout_modal .modal-body {
    padding: 160px 145px;
}
.logout_modal .logout_block2 {
    width: 100%;
    display: inline-block;
    position: relative;
}
.logout_modal .logout_block2 p {
    color: #1f1f1f;
    font-size: 26px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 82px;
}
.logout_modal .logout_block2 button {
    width: 100%;
    max-width: 300px;
    height: 48px;
    padding: 13px 15px;
    display: block;
    margin: auto;
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 600;
    line-height: 22px;
    color: #fff;
    border: 0;
    border-radius: 7px;
    cursor: pointer;
}

.logout_modal .logout_block2 button.btn-yes {
    background-color: #d0555a;
    margin-bottom: 46px;
}

.logout_modal .logout_block2 button.btn-no {
    background-color: #1f1f1f;
}

@media (max-width:1145px){
    .menuLink{
        margin-left: 40px;
    }
}

@media screen and (max-width:1023px){
    .container{
        padding: 0 15px;
    }
}
@media screen and (min-width:768px){
    .span-2, .span-3, .span-4, .span-5, .span-8 {
        float: left;
    }
}
/* status work home over */
@media screen and (min-width:768px) and (max-width:1023px){
    .sm-hidden{
        display: unset;
    }
    .xs-hidden{
        display: none !important;
    }
    .top-block3 p {
        text-align: center;
        margin-bottom: 30px;
    }
    .top-investing-block h4{
        margin-bottom: 15px;
    }
    .top-investing-block p {
        font-size: 20px;
        line-height: 30px;
        color: #fff;
    }
    .mobile-ui2,
    .mobile-Etherium,
    .mobile-signalResult{
        box-shadow: unset;
        margin: 0 auto;
        display: block;
    }
    .main-block{
        margin-top: 12.5rem;
    }
    .bitcoin_block3{
        width: calc(100% - 335px);
    }
    .bitcoin_block3 h3 {
        font-size: 34px;
    }
    .bitcoin_block3 p {
        font-size: 20px;
        line-height: 30px;
    }
    .bitcoin_block4 h3 {
        font-size: 34px;
    }
    .bitcoin_block4 p {
        font-size: 19px;
        line-height: 30px;
    }
    .download-block{
        text-align: center;
    }
    .play-storeBtn2{
        margin-left: 20px;
    }
    .xs-span-6 {
        width: 50%;
    }
    .width-100{
        width: 100%;
    }
    .copyright-block{
        text-align: center;
    }
    .play-storeBtn,.app-storeBtn,.get-premium-btn{
        margin-top: 25px;
    }
}
@media screen and (max-width:1023px){
    body{
        background: #F2F2F2;
    }
    .menuLink .logo_body{
        width: 100%;
        height: 185px;
        display: inline-block;
        position: relative;
        padding: 22px;
        text-align: center;
    }
    .navbar-header .navbar_logo{
        display: none;
    }
    .menuLink {
        width:295px;
        display: none;
        position: fixed;
        background: #F2F2F2;
        top:0px;
        left:-300px;
        bottom:0;
        overflow: hidden;
        overflow-y: auto;
        z-index: 999;
        margin-left: 0;
        transition: 0.3s linear;
    }
    .menuLink.active{
        left:0px;
        display: inline-block;
    }
    .menuLink li a.active::before{
        display: none;
    }
    .menuLink li{
        padding-left: 0;
        width: 100%;
    }
    .menuLink li a{
        padding: 10px 10px 10px 53px;
        width:100%;
        text-align: left;
    }
    .menuLink li a.active{
        background-color: #fff;
    }
    .menuLink li.drop ul li a.active{
        background-color: #fff;
        opacity: 1;
        font-weight: 900;
    }
    .menuLink li.drop a{
        display: none;
    }
    .menuLink li .drop_menu{
        display: inline-block;
        position: relative;
        top: 0;
        right: 0;
        background: transparent;
        box-shadow: none;
        width: auto;
        height: auto;
        padding: 0;
        min-width: 100%;
    }
    .menuLink li .drop_menu::before{
        display: none;
    }
    .menuLink li a:hover::before{
        content: '';
        display: none;
    }
    .menuLink li .drop_menu li a{
        padding: 10px 10px 10px 53px;
        width:100%;
        text-align: left;
    }
    .menuLink li .drop_menu li a:hover{
        padding: 10px 10px 10px 53px;
    }
    .navbar-header .toggle-btn{
        display: inline-block;
    }
    .navbar-header{
        max-width: 100%;
    }
    .navbar-header .btn_login{
        display: inline-block;
    }
    .header{
        padding-top: 22px;

    }
    .btn_blocks2 li .btn_login{
        margin-top: 0;
    }
    .bitcoin_block h1 {
        font-size: 34px;
    }
    .bitcoin_block p {
        font-size: 20px;
        line-height: 30px;
    }
    .app-storeBtn,.get-premium-btn{
        margin-right: 10px;
    }
    .app-storeBtn p,.play-storeBtn p{
        font-size: 17px;
    }
    .xs-span-3 {
        width: 25%;
    }
    .destop-view{
        display: block;
    }
    .bottom-footer{
        padding: 10px 0 7px;
    }
}
@media screen and (max-width:767px){
    .menuLink li a:hover::before{
        display: none;
    }
    .BuyBitcoin_body{
        padding: 69px 0;
    }
    .footer {
        padding: 69px 0 0;
    }
    .xs-span-3 {
        padding-right: 0;
        width: 25%;
    }
    .footer-menu li h4 {
        font-size: 11px;
    }
    .footer-menu li a {
        font-size: 10px;
        padding: 10px 0 0;
    }
    .xs-span-6 {
        width: 50%;
    }
    .footer-menu li p {
        font-size: 10px;
        margin-bottom: 0;
        margin-top: 16px;
    }
    .social-icons {
        padding-left: 0;
        margin-top: 22px;
    }
    .xs-hidden{
        display: none;
    }
    .xs-span-12 {
        width: 100%;
    }
    .copyright-block p {
        font-size: 10px;
        padding-top: 0;
    }
    .bg-buyBitcoin, .bg-buyBitcoin2 {
        padding: 69px 0;
    }
    .bitcoin_block {
        width: 100%;
    }
    .bitcoin_block h1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 11px;
    }
    .bitcoin_block p {
        font-size: 18px;
        text-align: center;
        line-height: 32px;
    }
    .app-storeBtn{
        display: block;
        margin: 0 auto;
        margin-top: 75px;
    }
    .get-premium-btn  {
        display: block;
        margin: 30px auto 127px;
    }
    .play-storeBtn {
        display: block;
        margin: 30px auto 32px;
    }
    .mobileUi-block {
        width: 100%;
        text-align: center;
    }
    .mobile-ui {
        float: unset;
        margin: 0 auto;
    }
    .bg-investing {
        background: transparent;
        top: 0;
        padding: 69px 0;
        height: auto;
    }
    .main-block {
        text-align: center;
        margin-top: 0;
    }
    .top-investing-block {
        width: 100%;
        margin-top: 0;
        padding: 0;
    }
    .top-investing-block h4 {
        font-size: 30px;
        color: #000;
        font-weight: bolder;
        text-align: center;
        margin-bottom: 11px;
    }
    .top-investing-block p {
        font-size: 18px;
        line-height: 32px;
        color: #000;
        margin-bottom: 25px;
    }
    .sm-hidden{
        display: unset;
    }
    .reset-modal-body { 
        padding: 0;
    }
    .box-cona {
        display: flex;
        flex-direction: column;
    }
    .box-cona #order2 {
        order: 2;
    }
    .bitcoin_block3 {
        width: 100%;
        top: 0;
    }
    .bitcoin_block3 h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 11px;
    }
    .bitcoin_block3 p {
        font-size: 18px;
        line-height: 32px;
        text-align: center;
    }
    .bitcoin_block4 {
        width: 100%;
        top: 0;
        float: unset;
    }
    .bitcoin_block4 h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 11px;
    }
    .bitcoin_block4 p {
        font-size: 18px;
        line-height: 32px;
        text-align: center;
    }
    .play-storeBtn2 {
        margin-bottom: 0;
    }
    .mobile-ui2 {
        box-shadow: unset;
    }
    .mobile-Etherium {
        box-shadow: unset;
        margin: 0;
    }
    .mobile-signalResult {
        box-shadow: unset;
    }
    .copyright-block {
        text-align: center;
    }
    .login_body .modal-content{
        padding: 82px 15px 45px;
    }
    .btn-resetP,.btn-cancel{
        font-size: 15px;
    }
    .btn-cancel{
        width: 46%;
    }
    .btn-block3 button{
        width:100%;
    }
    .btn-block3 button:last-child{
        margin-top: 25px;
    }
    .center-text{
        text-align: center !important;
        display: block !important;
    }
    .logout_modal .modal-body {
        padding: 40px 23px;
    }
}
@media screen and (max-width:320px){
    .btn-cancel{
        width: 45%;
    }
    .btn-resetP,.btn-cancel{
        font-size: 13px;
        padding: 10px 5px;
    }
    .btn-block3 button{
        width:100%;
    }
    .btn-block3 button:last-child{
        margin-top: 25px;
    }
    .bitcoin_block h1 {
        font-size: 26px;
    }
    .bitcoin_block p {
        font-size: 16px;
        line-height: 27px;
    }
    .top-investing-block h4 {
        font-size: 26px;
        color: #000;
        font-weight: bolder;
        text-align: center;
        margin-bottom: 11px;
    }
    .top-investing-block p {
        font-size: 16px;
        line-height: 27px;
        color: #000;
        margin-bottom: 25px;
    }
    .bitcoin_block3 h3 {
        font-size: 26px;
        text-align: center;
        margin-bottom: 11px;
    }
    .bitcoin_block3 p {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
    }
    .bitcoin_block4 h3 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 11px;
    }
    .bitcoin_block4 h3 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 11px;
    }
    .bitcoin_block4 p {
        font-size: 16px;
        line-height: 27px;
        text-align: center;
    }
}