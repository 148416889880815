.disc_body{
    padding: 84px 0;
}
.disc_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.disc-text{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    margin-top: 112px;
}
.disc-text p{
    font-family: 'SF Pro Text';
    font-size: 14px;
    margin-bottom: 16px;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: justify;
    color: #000000;
}
.disc-text p:last-child{
    margin-bottom: 0;
}
@media screen and (max-width:767px){
    .disc_body{
        padding: 69px 0;
    }
    .disc_body h1{
        font-size: 34px;
    }
    .disc-text{
        margin-top: 59px;
    }
}
