/* fonts */
/* SF Pro Text *
@font-face{
    font-family: 'SF Pro Text';
    font-weight: bold;
    src: local('SF Pro Text'), local('SF Pro Text Bold'), url('../fonts/SanFranciscoText-Bold.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: bold;
    font-style: italic;
    src: local('SF Pro Text'), local('SF Pro Text Bold'), url('../fonts/SanFranciscoText-BoldItalic.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: bolder;
    src: local('SF Pro Text'), local('SF Pro Text Heavy'), url('../fonts/SanFranciscoText-Heavy.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: bolder;
    font-style: italic;
    src: local('SF Pro Text'), local('SF Pro Text Heavy'), url('../fonts/SanFranciscoText-HeavyItalic.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: 300;
    src: local('SF Pro Text'), local('SF Pro Text Light'), url('../fonts/SanFranciscoText-Light.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: 300;
    font-style: italic;
    src: local('SF Pro Text'), local('SF Pro Text Light'), url('../fonts/SanFranciscoText-LightItalic.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: 500;
    src: local('SF Pro Text'), local('SF Pro Text Medium'), url('../fonts/SanFranciscoText-Medium.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: 500;
    font-style: italic;
    src: local('SF Pro Text'), local('SF Pro Text Medium'), url('../fonts/SanFranciscoText-MediumItalic.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: 400;
    src: local('SF Pro Text'), local('SF Pro Text Regular'), url('../fonts/SanFranciscoText-Regular.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-style: italic;
    src: local('SF Pro Text'), local('SF Pro Text Regular'), url('../fonts/SanFranciscoText-RegularItalic.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: 600;
    src: local('SF Pro Text'), local('SF Pro Text Semibold'), url('../fonts/SanFranciscoText-Semibold.otf') format('openType');
    display: swap;
}
@font-face{
    font-family: 'SF Pro Text';
    font-weight: 600;
    font-style: italic;
    src: local('SF Pro Text'), local('SF Pro Text Semibold'), url('../fonts/SanFranciscoText-SemiboldItalic.otf') format('openType');
    display: swap;
}
/* SF Pro Text over */
@font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-BoldItalic.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-BoldItalic.woff2') format('woff2'),
        url('../fonts/SFProText-BoldItalic.woff') format('woff'),
        url('../fonts/SFProText-BoldItalic.ttf') format('truetype'),
        url('../fonts/SFProText-BoldItalic.svg#SFProText-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-Bold.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-Bold.woff2') format('woff2'),
        url('../fonts/SFProText-Bold.woff') format('woff'),
        url('../fonts/SFProText-Bold.ttf') format('truetype'),
        url('../fonts/SFProText-Bold.svg#SFProText-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-Heavy.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-Heavy.woff2') format('woff2'),
        url('../fonts/SFProText-Heavy.woff') format('woff'),
        url('../fonts/SFProText-Heavy.ttf') format('truetype'),
        url('../fonts/SFProText-Heavy.svg#SFProText-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-HeavyItalic.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-HeavyItalic.woff2') format('woff2'),
        url('../fonts/SFProText-HeavyItalic.woff') format('woff'),
        url('../fonts/SFProText-HeavyItalic.ttf') format('truetype'),
        url('../fonts/SFProText-HeavyItalic.svg#SFProText-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-Light.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-Light.woff2') format('woff2'),
        url('../fonts/SFProText-Light.woff') format('woff'),
        url('../fonts/SFProText-Light.ttf') format('truetype'),
        url('../fonts/SFProText-Light.svg#SFProText-Light') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-LightItalic.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-LightItalic.woff2') format('woff2'),
        url('../fonts/SFProText-LightItalic.woff') format('woff'),
        url('../fonts/SFProText-LightItalic.ttf') format('truetype'),
        url('../fonts/SFProText-LightItalic.svg#SFProText-LightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-Medium.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-Medium.woff2') format('woff2'),
        url('../fonts/SFProText-Medium.woff') format('woff'),
        url('../fonts/SFProText-Medium.ttf') format('truetype'),
        url('../fonts/SFProText-Medium.svg#SFProText-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-MediumItalic.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-MediumItalic.woff2') format('woff2'),
        url('../fonts/SFProText-MediumItalic.woff') format('woff'),
        url('../fonts/SFProText-MediumItalic.ttf') format('truetype'),
        url('../fonts/SFProText-MediumItalic.svg#SFProText-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-Semibold.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-Semibold.woff2') format('woff2'),
        url('../fonts/SFProText-Semibold.woff') format('woff'),
        url('../fonts/SFProText-Semibold.ttf') format('truetype'),
        url('../fonts/SFProText-Semibold.svg#SFProText-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-RegularItalic.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-RegularItalic.woff2') format('woff2'),
        url('../fonts/SFProText-RegularItalic.woff') format('woff'),
        url('../fonts/SFProText-RegularItalic.ttf') format('truetype'),
        url('../fonts/SFProText-RegularItalic.svg#SFProText-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-Regular.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-Regular.woff2') format('woff2'),
        url('../fonts/SFProText-Regular.woff') format('woff'),
        url('../fonts/SFProText-Regular.ttf') format('truetype'),
        url('../fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url('../fonts/SFProText-SemiboldItalic.eot');
    src: local('SF Pro Text'), url('../fonts/SFProText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SFProText-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/SFProText-SemiboldItalic.woff') format('woff'),
        url('../fonts/SFProText-SemiboldItalic.ttf') format('truetype'),
        url('../fonts/SFProText-SemiboldItalic.svg#SFProText-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
 }
 
/* SF Pro Display */
@font-face{
    font-family: 'SF Pro Display';
    font-weight: 300;
    src: local('SF Pro Display Light'), url('../fonts/SF-Pro-Display-Light.otf') format('openType');
}
@font-face{
    font-family: 'SF Pro Display';
    font-weight: 400;
    src: local('SF Pro Display Regular'), url('../fonts/SF-Pro-Display-Regular.otf') format('openType');
}
@font-face{
    font-family: 'SF Pro Display';
    font-weight: 500;
    src: local('SF Pro Display Medium'), url('../fonts/SF-Pro-Display-Medium.otf') format('openType');
}
@font-face{
    font-family: 'SF Pro Display';
    font-weight: 600;
    src: local('SF Pro Display Semibold'), url('../fonts/SF-Pro-Display-Semibold.otf') format('openType');
}
@font-face{
    font-family: 'SF Pro Display';
    font-weight: 800;
    src: local('SF Pro Display Bold'), url('../fonts/SF-Pro-Display-Bold.otf') format('openType');
}
@font-face{
    font-family: 'SF Pro Display';
    font-weight: 900;
    src: local('SF Pro Display Black'), url('../fonts/SF-Pro-Display-Black.otf') format('openType');
}
/* SF Pro Display over*/
/* SF UI TEXT  */
@font-face {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 300;
    src: local('SF UI Text Light'), url('../fonts/SFUIText-Light.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 400;
    src: local('SF UI Text Regular'), url('../fonts/SFUIText-Regular.woff') format('woff');
}
    
@font-face {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 500;
    src: local('SF UI Text Medium'), url('../fonts/SFUIText-Medium.woff') format('woff');
}
    
@font-face {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: 600;
    src: local('SF UI Text Semibold'), url('../fonts/SFUIText-Semibold.woff') format('woff');
}
    
@font-face {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: bold;
    src: local('SF UI Text Bold'), url('../fonts/SFUIText-Bold.woff') format('woff');
}
    
@font-face {
    font-family: 'SF UI Text';
    font-style: normal;
    font-weight: bolder;
    src: local('SF UI Text Heavy'), url('../fonts/SFUIText-Heavy.woff') format('woff');
}
/* DIN font */
@font-face {
    font-family: 'DIN Condensed Bold';
    font-style: normal;
    font-weight: normal;
    src: local('DIN Condensed Bold'), url('../fonts/DINCondensed-Bold.woff') format('woff');
}
/* DIN font over*/
/* Helvetica Neue font */
@font-face {
    font-family: 'Helvetica Neue Light';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue Light'), url('../fonts/HelveticaNeueLight.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica Neue Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue Medium'), url('../fonts/HelveticaNeueMedium.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica Neue Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue Bold'), url('../fonts/HelveticaNeueBold.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica Neue Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue Regular'), url('../fonts/Helvetica-Neue-Regular.woff') format('woff');
}
/* Helvetica Neue font over */
