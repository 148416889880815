.about_body{
    padding: 84px 0;
}
.about_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.about_blo{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    margin-top: 112px;
}
.about_blo h6, .about_blo p{
    font-family: 'SF Pro Text';
    font-size: 14px;
    margin-bottom: 0;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: justify;
    color: #000000;
}
.about_blo h6{
    font-weight: bold;
    line-height: 44px;
    font-size: 18px;
    margin-bottom: 27px;
}
@media screen and (max-width:767px){
    .about_body{
        padding: 69px 0;
    }
    .about_body h1{
        font-size: 34px;
    }
    .about_blo{
        margin-top: 59px;
    }
    .about_blo h6{
        font-size: 14px;
        line-height: 30px;
        margin-bottom: 15px;
    }
}