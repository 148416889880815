.policy_body{
    padding: 84px 0;
}
.policy_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.policy_ul{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    margin-top: 112px;
}
.policy_ul li{
    list-style: none;
    width:100%;
    display: inline-block;
    position: relative;
    margin-bottom: 16px;
}
.policy_ul li:last-child{
    margin-bottom: 0;
}
.policy_ul li h6,.policy_ul li p{
    font-family: 'SF Pro Text';
    font-size: 14px;
    margin-bottom: 0;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: justify;
    color: #000000;
}
.policy_ul li h6{
    font-weight: bold;
    line-height: 44px;
    font-size: 18px;
}
.policy_ul li:first-child p:last-child{
    margin-top: 16px;
}
.policy_ul li:nth-last-child(2) p{
    margin-bottom: 16px;
}
.policy_ul li:nth-last-child(2) p:last-child,
.policy_ul li:nth-last-child(2) p:nth-last-child(2){
    margin-bottom: 0;
}
@media screen and (max-width:767px){
    .policy_body{
        padding: 69px 0;
    }
    .policy_body h1{
        font-size: 34px;
    }
    .policy_ul{
        margin-top: 59px;
    }
    .policy_ul li h6{
        font-size: 14px;
        line-height: 30px;
        margin-bottom: 15px;
    }
}