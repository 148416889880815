.news_body{
    padding: 84px 0;
}
.news_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.news_main{
    width: 100%;
    max-height: 750px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 5px 13px rgba(64,85,196,0.14);
    margin-top: 82px;
    padding: 70.5px 0;
    margin-bottom: 0;
}
.news_ul{
    width: 100%;
    height: 100%;
    padding: 0 149.5px;
    max-height: 610px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 0;
}
.news_ul li{
    list-style: none;
    width: 100%;
    display: inline-block;
    position: relative;
}
.news_ul li .news-block{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 26px;
}
.news_ul li:last-child .news-block{
    margin-bottom: 0;
}
.news_ul li .news-block .news-text{
    width: 100%;
    display: inline-block;
    position: relative;
}
.news_ul li .news-block .news-text a{
    font-family: 'SF UI Text';
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    line-height: 22px;
    margin-bottom: 20px;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    max-width: 552px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    display: inline-block;
    position: relative;
}
.news_ul li .news-block .news-text a:hover,
.news_ul li .news-block .news-text a:focus{
    outline: 0;
    text-decoration: none;
}
.news_ul li .news-block .news-text p{
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0;
    color: #8C96A9;
    margin-bottom: 0;
}
.news_ul li .news-block .newsImg_block{
    display: inline-block;
    position: relative;
}
.news_ul li .news-block .newsImg_block img{
    width:80px;
    border-radius: 5px;
    border:0;
}
@media screen and (max-width:1023px){
    .news_body{
        padding: 69px 0;
        display: inline-block;
        width: 100%;
    }
    .news_body h1 {
        font-family: 'Montserrat', sans-serif;
        font-size: 34px;
        font-weight: 700;
    }
    .news_main{
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        box-shadow: unset;
        margin-top: 48px;
    }
    .news_ul{
        padding: 0;
        max-height: 100%;
        overflow: unset;
    }
    .news_ul li .news-block .news-text a{
        font-size: 16px;
        max-width: 250px;
        margin-bottom: 0;
    }
    .news_ul li .news-block .news-text p{
        font-size: 13px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 250px;
        white-space: nowrap;
    }
}
@media screen and (max-width:768px){
    .news_ul li .news-block .news-text a,
    .news_ul li .news-block .news-text p{
        white-space: normal;
        max-width: 100%;
    }
}
@media screen and (max-width:425px){
    .news_ul li .news-block .news-text a,
    .news_ul li .news-block .news-text p{
        max-width: 290px;
        white-space: nowrap;
    }
}
@media screen and (max-width:375px){
    .news_ul li .news-block .news-text a,.news_ul li .news-block .news-text p{
        max-width: 250px;
        white-space: nowrap;
    }
}
@media screen and (max-width:320px){
    .news_ul li .news-block .news-text a{
        font-size: 16px;
        max-width: 190px;
        margin-bottom: 5px;
        white-space: nowrap;
    }
    .news_ul li .news-block .news-text p{
        font-size: 13px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 190px;
        white-space: nowrap;
    }
}