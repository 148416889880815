.block_404{
    padding: 80px 50px 80px;
    margin: 150px auto 30px auto;
    display: flex;
    align-items: center;
    min-height: 608.83px;
    max-width: 927px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(99,111,175,0.14);
}
.block_404 .img_bloc{
    width:100%;
    text-align: center;
}
.block_404 .had-block{
    width: 100%;
    display: inline-block;
    position: relative;
}
.block_404  .had-block h3{
    font-size: 160px;
    color: #e70e3b;
    font-family: 'DIN Condensed Bold';
    font-weight: 300;
    letter-spacing: 7.94px;
    line-height: 100px;
    text-align: left;
}
.block_404  .had-block h6{
    font-size: 26px;
    color: #e70e3b;
    font-family: 'DIN Condensed Bold';
    font-weight: bold;
    letter-spacing: 1.73px;
    line-height: 28px;
    margin-top: 45px;
    text-align: left;
}
.block_404  .had-block p{
    font-size: 18px;
    font-family: 'SF Pro Text';
    color: #9B9B9B;
    line-height: 28px;
    text-align: left;
    font-weight: 400;
    letter-spacing: 0px;
}
.btn-backto2{
    width:100%;
    max-width: 215px;
    height:45px;
    border-radius: 60px;
    background: #E70E3B url('../../assets/image/go-arrow.svg') no-repeat 85% 50%;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    display: inline-block;
    color:#fff;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 24px;
    padding: 11px 60px 11px 33px;
    text-decoration: none;
    box-shadow: 0 2px 14px 0 rgba(64,85,196,0.14);
    margin-left: 0px;
    margin-top: 35px;
}
.btn-backto2:hover,.btn-backto2:focus{
    outline: 0;
    color: #ffffff;
    text-decoration: none;
}
@media screen and (max-width:1023px){
    .block_404 {
        padding: 15px;
        display: inline-block;
        margin-bottom: 100px;
        text-align: center;
    }
    .block_404  .img-block{
        margin-bottom: 15px;
        display: inline-block;
        position: relative;
        width: 100%;
    }
    .block_404  .had-block h3{
        text-align:center;
        margin-top: 80px;
    }
    .block_404  .had-block h6,.block_404  .had-block p{
        text-align: center;
    }
    .btn-backto2{
        margin: 15px auto;
    }
}