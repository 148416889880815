.BuyCrypto_body{
    padding: 84px 0;
}
.BuyCrypto_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.crypto-content-wrap{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top:82px;
}
.search-crypto{
    height: 60px;
    width: 100%;
    border-radius: 7px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(209,214,232,0.53);
    display: inline-block;
    position: relative;
}
.search-cInput{
    border: none;
    height: 100%;
    width: 73%;
    color: #8C96A9;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    line-height: 15px;
    border-radius: 7px 0 0 7px;
    padding: 22px 56px 20px 56px;
}
.search-filter-crypto{
    width:27%;
    display: flex;
    align-items: center;
}
.search-filter-crypto label{
    color: #9B9EAD;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding: 22px 0px 20px 0px;
    margin-bottom: 0;
    font-weight: 700;
    display: inline-block;
    position: relative;
    width: 50%
}
.search-cInput,.search-filter-crypto{
    float: left;
}
.filter-by{
    width: 100%;
}
.filter-by input{
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    height: 60px;
    border: 0;
    background: transparent url('../../assets/image/red-arrow.png') no-repeat 80% 55%;
    background-size: 24px;
    color: #d0555a;
    font-weight: 500;
    cursor: pointer;
}
.filter-by p{
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    height: 60px;
    border: 0;
    background: transparent url('../../assets/image/red-arrow.png') no-repeat 80% 55%;
    background-size: 24px;
    color: #d0555a;
    font-weight: 500;
    cursor: pointer;
    padding: 18px 0;
}
.drop_menu2{
    background-color: #FBFBFB;
    box-shadow: 0 5px 13px 0 rgba(64,85,196,0.14);
    width: 224px;
    overflow-y: visible;
    display: none;
    position: absolute;
    right:0;
    top:0;
    z-index: 999;
    padding: 41px 0;
    margin-bottom: 0;
    border-radius: 15px;
}
.drop_menu2 li{
    padding: 0 28px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 32px;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    transition: all 0.3s ease;
    white-space: nowrap;
    color: #000000;
}
.drop_menu2 li:last-child{
    margin-bottom: 0;
}
.drop_menu2 li.active{
    color: #d0555a;
    font-weight: 500;
}
.filter-by.active .drop_menu2{
    display: inline-block;
}
.cypto-content{
    width:100%;
    display: inline-block;
    position: relative;
    margin-top: 49px;
}
.crypto-left{
    width:30%;
    display: inline-block;
    position: relative;
    padding-right: 20px;
}
.country_block{
    width: 271px;
    display: inline-block;
    position: relative;
    border-radius: 7px 7px 0 0;
    border: 1px solid #F9FAFC;
    background-color: #FFFFFF;
    padding: 19.5px 13.5px 15.5px 30.5px;
}
.country_input{
    width: 100%;
    height: 40px;
    display: inline-block;
    position: relative;
    background: #F9FAFC url('../../assets/image/arrow-down.svg') no-repeat 95% 50%;
    padding: 10px 10px 10px 36px;
    border:0;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #1F1F1F;
    transition: 0.3s linear;
    cursor: pointer;
}
.country_input.active{
    background: #F9FAFC url('../../assets/image/arrow-up.svg') no-repeat 95% 50%;
}
.gps_icon{
    position: absolute;
    top: 32px;
    left: 38px;
    background:transparent url('../../assets/image/country-se.svg') no-repeat 0 0;
    width: 17px;
    height: 17px;
    z-index: 2;
}
.drop_menu3{
    width: 224px;
    max-height: 250px;
    display: none;
    position: absolute;
    top:60px;
    left:30px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
    margin-bottom: 0;
    transition: 0.3s linear;
    z-index: 4;
}
.drop_menu3 li{
    list-style: none;
    width: 100%;
    display: inline-block;
    position: relative;
}
.drop_menu3 li .ckeck_block{
    width:100%;
    display: inline-block;
    position: relative;
}
.drop_menu3 li .ckeck_block input[type="checkbox"]{
    display: none;
}
.drop_menu3 li .ckeck_block label{
    width: 100%;
    display: inline-block;
    position: relative;
    background: url('../../assets/image/checkbox.svg') no-repeat 6% 50%;
    padding: 15px 25px 15px 50px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    color: #1F1F1F;
    margin-bottom: 0;
}
.drop_menu3 li .ckeck_block input[type="checkbox"]:checked ~ label{
    background: url('../../assets/image/checkbox-check.svg') no-repeat 6% 50%;
}
.drop_menu3.active{
    display: inline-block;
}
.crypto-left-wrap{
    padding: 19.5px 13.5px 15.5px 30.5px;
    box-sizing: border-box;
    width: 271px;
    border: 1px solid #F9FAFC;
    background-color: #FFFFFF;
    overflow-y: hidden;
}
.label-crypto{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 300;
    color: #1F1F1F;
    margin-bottom: 0;
    cursor: pointer;
    background: url('../../assets/image/arrow-up.svg') no-repeat 95% 50%;
    width: 100%;
    transition: 0.3s linear;
}
.label-crypto.active{
    background: url('../../assets/image/arrow-down.svg') no-repeat 95% 50%;
}
.drop_menu4{
    width: 100%;
    display: inline-block;
    position: relative;
    background-color: transparent;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 4px;
    /* box-shadow: 0 4px 5px rgba(0, 0, 0, .15); */
    margin-bottom: 0;
    margin-top: 25px;
    z-index: 3;
    height: 136px;
    transition: height 0.3s linear;
}
.drop_payment{
    height: 180px;
}
.drop_menu4 li{
    list-style: none;
    width: 100%;
    display: inline-block;
    position: relative;
}
.drop_menu4 li .ckeck_block{
    width:100%;
    display: inline-block;
    position: relative;
}
.drop_menu4 li .ckeck_block input[type="checkbox"],
.drop_menu4 li .ckeck_block input[type="radio"]{
    display: none;
}
.drop_menu4 li .ckeck_block label{
    width: 100%;
    display: inline-block;
    position: relative;
    background: url('../../assets/image/checkbox.svg') no-repeat 0% 50%;
    padding: 15px 25px 15px 39px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    color: #1F1F1F;
    margin-bottom: 0;
    text-transform: capitalize;
}
.drop_menu4 li .ckeck_block input[type="checkbox"]:checked ~ label,
.drop_menu4 li .ckeck_block input[type="radio"]:checked ~ label{
    background: url('../../assets/image/checkbox-check.svg') no-repeat 0% 50%;
}
.label-crypto.active ~ .drop_menu4{
    height: 0;
    margin-top: 0;
}
.crypto-left .crypto-left-wrap:last-child {
    border-radius: 0 0 7px 7px;
}
/* single toggel */
.drop_menu4 .ckeck_block .label_featrure{
    width: 100%;
    display: inline-block;
    position: relative;
    background: transparent url('../../assets/image/checkbox.svg') no-repeat 0% 50%;
    border:0;
    padding: 15px 25px 15px 39px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    color: #1F1F1F;
    margin-bottom: 0;
    text-align: left;
    cursor: pointer;
}
.drop_menu4 .ckeck_block .label_featrure.checked{
    background: transparent url('../../assets/image/checkbox-check.svg') no-repeat 0% 50%;
}
.drop_menu4 .ckeck_block .label_featrure:hover,
.drop_menu4 .ckeck_block .label_featrure:focus{
    outline: 0;
}
/* single toggel over */
/* multi search */
.block_seacrh{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 31px;
    height: 225px;
    transition: height 0.3s linear;
}
.label-crypto.active ~ .block_seacrh{
    height: 0px;
    margin-top: 0;
}

.block_seacrh .drop_menu4{margin-top: 20px}
.seacrh-body{
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    transition: height 0.3s linear;
}
.search_input .css-1hwfws3 .react-select-2-input{
    width: 100%;
    min-height: 40px;
    display: inline-block;
    position: relative;
    background: #F9FAFC;
    padding: 10px 10px 10px 36px;
    border:0;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #1F1F1F;
    transition: 0.3s linear;
    cursor: pointer;
}
.search_input .css-1g48xl4-IndicatorsContainer{
    display: none;
}
.search_input .css-26l3qy-menu{
    z-index: 5;
}
.search_input .css-1rhbuit-multiValue{
    background-color: #3B7CFF;
    border-radius: 3px;
}
.search_input .css-1rhbuit-multiValue div:first-child{
    font-family: 'Montserrat', sans-serif;
    color: #fff;
}
.search_input .css-1rhbuit-multiValue div:last-child svg{
    color: #FFFFFF;
}
.search_input .css-1rhbuit-multiValue div:last-child{
    cursor: pointer;
}
.search_input .css-1rhbuit-multiValue div:last-child:hover,
.search_input .css-1rhbuit-multiValue div:last-child:focus{
    background: transparent;
    outline: 0;
}
.drop_multi{
    width: 224px;
    max-height: 250px;
    display: none;
    position: absolute;
    top: 100%;
    left: 1px;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
    margin-bottom: 0;
    transition: 0.3s linear;
    z-index: 4;
    padding: 10px 0;
}
.drop_multi.active{
    display: inline-block;
}
.drop_multi li{
    list-style: none;
    width: 100%;
    display: inline-block;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #1F1F1F;
    cursor: pointer;
    padding: 5px 15px;
}
.search_input span  .removetag{
    padding: 10px;
}
.search_input .css-yk16xz-control{
    background-color: #F9FAFC;
    border:0;
    border-radius: 5px;
}
.search_input .css-1pahdxg-control{
    border-color:transparent;
    background-color: #F9FAFC;
    box-shadow: none;
}
.search_input .css-1pahdxg-control:hover{
    box-shadow: none;
    border-color:transparent;
}
.search_input .css-12jo7m5{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.search_input .css-1wa3eu0-placeholder{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.drop_coins{
    height: 395px;
    max-width: 395px;
}
.label-crypto.active ~ .block_seacrh .seacrh-body,
.label-crypto.active ~ .block_seacrh .drop_menu4{
    height: 0;
    display: none;
}
/* multi search over */
/* crypto-right */
.crypto-left,.crypto-right{
    float: left;
}
.crypto-right{
    width: 70%;
    height: 1130px;
    overflow: hidden;
    /* overflow-y: auto; */
}
.website_body{
    width: 100%;
    height: 1130px;
    overflow: hidden;
    overflow-y: auto;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
}
.website_body li{
    list-style: none;
    width: 100%;
    padding-right: 10px;
}
.website_body li .website_block{
    border-radius: 7px;
    background-color: #FFFFFF;
    padding: 33.5px 38px;
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 30px;
}
.website_body li:last-child .website_block{
    margin-bottom: 0;
}
.website_body li .website_block .top-bloc{
    width: 100%;
    display: flex;
    align-items: center;
}
.website_body li .website_block .top-bloc .num-blo p{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #424C5F;
    margin-bottom: 0;
}
.website_body li .website_block .top-bloc .had-block{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.website_body li .website_block .top-bloc .had-block img{
    width: 35px;
    border-radius: 8px;
    margin: 0 23px;
}
.website_body li .website_block .top-bloc .had-block a{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #1F1F1F;
    margin-bottom: 0;
    text-decoration: none;
}
.website_body li .website_block .top-bloc .had-block a:hover,
.website_body li .website_block .top-bloc .had-block a:focus{
    outline: 0;
}
.website_body li .website_block .top-bloc .button-block a{
    height: 35px;
    width: 150px;
    border-radius: 30px;
    display: inline-block;
    position: relative;
    background-color: #3B7CFF;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    border: none;
    text-decoration: none;
    text-align: center;
    padding: 8px 10px;
    letter-spacing: 0.75px;
    cursor: pointer;
}
.website_body li .website_block .top-bloc .button-block a:hover,
.website_body li .website_block .top-bloc .button-block a:focus{
    outline: 0;
}
.website_body li .website_block .bottom-bloc{
    width: 100%;
    display: inline-block;
    position: relative;
}
.website_body li .website_block .bottom-bloc .rating-block{
    width: 100%;
    margin-bottom: 0;
    margin-top: 38px;
}
.website_body li .website_block .bottom-bloc .rating-block li{
    list-style: none;
    width: calc(100% / 6);
    float: left;
    text-align: center;
    padding-right: 0;
}
.website_body li .website_block .bottom-bloc .rating-block li h6{
    color: #1F1F1F;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 19px;
    margin-bottom: 10px;
}
.mobile-v{
    display: none;
}
/* crypto-right over */
/* sort design */

.sort-block{
    width: 100%;
    margin-bottom: 40px;
}
.sort-block li{
    list-style: none;
    width:calc(100% / 3);
    float: left;
}
.sort-block li:nth-child(2){
    padding: 0 13px;
}
.sort-input{
    width: 100%;
    display: inline-block;
    position: relative;
    height: 36px;
    border: 0;
    border-radius: 7px;
    background: #FFFFFF url('../../assets/image/sort-icon.svg') no-repeat 90% 50%;
    /* padding: 5px 20px 5px 12px; */
    padding: 11px 25px 11px 12px;
    font-family: 'Montserrat',sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
    line-height: 15px;
    color: #424C5F;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}
.sort-input:hover,.sort-input:focus{
    outline: 0;
    box-shadow: unset;
}
.drop-menu3{
    width:100%;
    max-width: 280px;
    background-color:#FFFFFF;
    border-radius: 5px;
    border:0;
    margin-bottom: 0;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0px); 
    display:none;
}
.drop-menu3 .ul-drop{
    margin-bottom: 0;
    width: 100%;
    display: inline-block;
    position: relative;
    max-height: 193px;
    overflow: hidden;
    overflow-y: auto;
}
.drop-menu3 .ul-drop li{
    list-style: none;
    width: 100%;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0;
    color: #424C5F;
    padding: 15px 45px 15px 28px;
    cursor: pointer;
}
.drop-menu3 .ul-drop li:nth-child(2){
    padding: 15px 28px;
}
.drop-menu3 label.valu{
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
    width:100%;
    padding: 15px 28px;
}
.drop-menu3 li.active{
    font-weight: bold;
    background: transparent url('../../assets/image/right-icon.svg') no-repeat 90% 50%;
}
.drop-menu3 li:last-child{
    margin-bottom: 15px;
}
.sort-input.active ~ .drop-menu3{
    display: inline-block;
}
.country_block .css-48ayfv ~ div{
    z-index: 5 !important;
    margin-top: 0 !important;
}
.country_block .css-1r4vtzz, .country_block .css-48ayfv{
    width: 100%;
    width: 100%;
    height: 40px;
    display: inline-block;
    position: relative;
    background: #F9FAFC url('../../assets/image/arrow-down.svg') no-repeat 95% 50%;
    padding: 10px 10px 10px 36px;
    border: 0;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #1F1F1F;
    transition: 0.3s linear;
    cursor: pointer;
    box-shadow: none;
}
.country_block .css-1gpjby2{
    display: none;
}
.country_block button ~ div:last-child{
    display: none;
}
.cuntryDro .css-1jllj6i-control{
    display: none;
}
.cuntryDro .css-ik6y5r .css-11unzgr{
    max-width: 225px;
    overflow-x: hidden;
}

.cuntryDro .css-11unzgr{
    max-height: 250px;
}
.cuntryDro .css-1vr111p-option{
    width: 100%;
    display: inline-block;
    position: relative;
    background: url('../../assets/image/checkbox.svg') no-repeat 6% 50%;
    padding: 15px 25px 15px 50px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    color: #1F1F1F;
    margin-bottom: 0;
}
.cuntryDro .css-1vr111p-option input[type="checkbox"]{
    display: none;
}
.cuntryDro .css-1qprcsu-option {
    width: 100%;
    display: inline-block;
    position: relative;
    background: url('../../assets/image/checkbox-check.svg') no-repeat 6% 50%;
    padding: 15px 25px 15px 50px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    color: #1F1F1F;
    margin-bottom: 0;
}
.cuntryDro .css-1qprcsu-option input[type="checkbox"]{
    display: none;
}
/* media query */
@media screen and (max-width:1023px){
    .desktop-v{
        display: none;
    }
    .mobile-v{
        display: inline-block;
    }
    .BuyCrypto_body{
        padding: 43px 0;
    }
    .BuyCrypto_body h1{
        font-family: 'Montserrat', sans-serif;
        font-size: 34px;
        font-weight: 700;
        text-align: left;
        letter-spacing: -1px;
        
    }
    .crypto-content-wrap{
        margin-top: 45px;
    }
    .search-crypto{
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        height: unset;
    }
    .search-cInput{
        display: none;
    }
    .search-filter-crypto{
        width:50%;
        position: absolute;
        top:-94px;
        right:0;
        max-width: 125px;
    }
    .filter-by input,
    .filter-by p{
        height: 30px;
        background-position: 95% 83%;
        font-size: 15px;
        background-size: 18px;
        padding: 5px 0;
    }
    .search-filter-crypto label{
        display: none;
    }
    .crypto-left, .crypto-right{
        float: unset;
        width:100%;
    }
    .crypto-left{
        display: none;
    }
    .cypto-content{
        margin-top: 0;
    }
    .website_body li .website_block{
        padding: 20px 20px;
    }
    .website_body li .website_block .top-bloc .num-blo p{
        font-size: 20px;
    }
    .website_body li .website_block .top-bloc .had-block img{
        width: 32px;
        height: 32px;
        border-radius: 8px;
        margin: 0 23px 0 17px;
    }
    .website_body li .website_block .top-bloc .had-block a{
        font-size: 20px;
    }
    .website_body li .website_block .top-bloc .button-block a{
        width: 119px;
        font-size: 14px;
    }
    .crypto-right{
        height: auto;
        overflow: unset;
    }
    .website_body li{
        padding-right: 0;
    }
    .website_body li .website_block .bottom-bloc .rating-block li h6{
        font-size: 12px;
    }
    .website_body li .website_block .bottom-bloc .rating-block li img{
        max-width:50px;
    }
    .website_body li .website_block .bottom-bloc .rating-block li:last-child{
        display: none;
    }
    .website_body li .website_block .bottom-bloc .rating-block li{
        width:calc(100% / 5);
    }
    .sort-input{
        background-position: 95% 50%;
    }
}
@media screen and (max-width:767px){
    .website_body li .website_block{
        padding: 20px 11px;
    }
    .website_body li .website_block .top-bloc .button-block a{
        width: 85px;
        font-size: 12px;
    }
    .website_body li .website_block .top-bloc .num-blo p{
        font-size: 14px;
    }
    .website_body li .website_block .top-bloc .had-block img{
        width: 32px;
        height: 32px;
        border-radius: 8px;
        margin: 0 12px 0 12px;
    }
    .website_body li .website_block .top-bloc .had-block a{
        font-size: 16px;
    }
    .sort-input{
        background-position: 90% 50%;
    }
}
@media screen and (max-width:320px){
    .BuyCrypto_body h1{
        font-size: 30px;
    }
    .search-filter-crypto{
        width:35%;
    }
    .filter-by input,
    .filter-by p{
        font-size: 12px;
        background-size: 14px;
        background-position: 95% 65%;
        padding: 5px 0;
    } 
    .website_body li .website_block .bottom-bloc .rating-block li h6{
        font-size: 11px;
    }
    .website_body li .website_block .bottom-bloc .rating-block li img{
        max-width:45px;
    }
    .website_body li .website_block .top-bloc .had-block a{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 98px;
    }
    .sort-input{
        padding: 11px 20px 11px 12px;
    }
}