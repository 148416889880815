.notFound{
    width:100%;
    display: inline-block;
    position: relative;
    background: #f2f2f2;
    border-radius: 8px;
    padding: 75px 35px;
    text-align: center;
    opacity: 0.2;
}
.notFound p{
    font-family: 'Helvetica Neue Bold';
    font-size: 35px;
    line-height: 50px;
    color: #d0555a;
    margin: 50px auto 0;
}