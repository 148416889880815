.youtube_body2{
    padding: 84px 0;
    
}
.youtube_body2 h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.youtube_content{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 90px;
}
.yt-tab-wrap{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 0;
}
.yt-tab-wrap li{
    list-style: none;
    width: 100%;
}
.yt-tab-wrap li:nth-child(2){
    margin: 0 3.5%;
}
.yt-tab-wrap li .tab-search{
    width: 100%;
    display: inline-block;
    position: relative;
    max-width: 300px;
}
.yt-tab-wrap li .tab-search .form-youtube{
    border: none;
    height: 60px;
    width: 100%;
    color: #8C96A9;
    font-family: 'Montserrat', sans-serif;
    background-color: #FFFFFF;
    font-size: 20px;
    line-height: 15px;
    border-radius: 7px;
    padding-left: 45px;
    padding-right: 45px;
}
.yt-tab-wrap li .tab-search .form-youtube:hover,
.yt-tab-wrap li .tab-search .form-youtube:focus{
    outline: 0;
}
.yt-tab-wrap li .lang_block{
    width: 100%;
    max-width: 300px;
}
.yt-tab-wrap li .lang_block .click-block{
    width: 100%;
    height: 60px;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 7px;
    background-color: #FFFFFF;
}
.yt-tab-wrap li .lang_block .click-block label{
    margin-bottom: 0;
    color: #9B9EAD;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    padding-left: 44px;
}
.yt-tab-wrap li .lang_block .click-block .input_youtube{
    width: 60%;
    height: 60px;
    display: inline-block;
    position: relative;
    background: transparent url('../../assets/image/sort-icon.svg') no-repeat 90% 50%;
    border:0;
    margin-left:15px;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #1F1F1F;
    line-height: 20px; 
    cursor: pointer;
    text-align: center;
    padding-right: 35px;
    padding:21px 35px 21px 0px;
}
.yt-tab-wrap li .lang_block .click-block .input_youtube:hover,
.yt-tab-wrap li .lang_block .click-block .input_youtube:focus{
    outline: 0;
}
.yt-tab-wrap li .lang_block .click-block .input_youtube[name="sort_popular"]{
    background: transparent url('../../assets/image/ArrowDown_Icon.svg') no-repeat 90% 53%;
    color: #F44336;
}
.yt-tab-wrap li:last-child .lang_block .click-block .input_youtube{
    width: 58%;
}
.yt-tab-wrap li .lang_block .drop_block2{
    width: 100%;
    max-width: 300px;
    background: #fff;
    z-index: 999;
    position: absolute;
    top: 0;
    border-radius: 7px;
    display: none;
    transition: 0.3s linear;
}
.yt-tab-wrap li .lang_block .drop_block2 label{
    width:100%;
    padding: 20px 0;
    display: block;
    text-align: center;
    background: transparent;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}
.yt-tab-wrap li .lang_block .drop_block2 .menu_drop{
    margin-bottom: 0;
    width: 100%;
    display: inline-block;
    position: relative;
    max-height: 193px;
    overflow: hidden;
    overflow-y: auto;
}
.yt-tab-wrap li .lang_block .drop_block2 .menu_drop li:nth-child(2){
    margin: unset;
}
.yt-tab-wrap li .lang_block .drop_block2 .menu_drop li{
    list-style: none;
    width: 100%;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0;
    color: #424C5F;
    padding: 15px 28px;
    cursor: pointer;
}
.yt-tab-wrap li .lang_block .drop_block2 .menu_drop li.active{
    background: transparent url('../../assets/image/right-icon.svg') no-repeat 90% 50%;
    color: #d0555a;
    font-weight: 500;
}
.yt-tab-wrap li .lang_block .drop_block2 .menu_drop li:last-child{
    margin-bottom: 15px;
}
.yt-tab-wrap li .lang_block .drop_block2.active{
    display: inline-block !important;
}
.channel_block2{
    width: 100%;
    max-height: 900px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 35px;
    margin-bottom: 0px;
}
.channel_block2 li{
    list-style: none;
    width:100%;
    display: inline-block;
    padding-right: 10px;
}
.channel_block2 li .channel_body2{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    background-color: #FFFFFF;
    border: 0;
    border-radius: 7px;
    padding: 27px 46px;
    margin-bottom: 35px;
}
.channel_block2 li:last-child .channel_body2{
    margin-bottom: 0;
}
.channel_block2 li .channel_body2 .num-bl p{
    margin-bottom: 0;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    color: #424C5F;
}
.channel_block2 li .channel_body2 .text-content2{
    width: 100%;
    display: inline-block;
    position: relative;
    padding-left: 34px;
}
.channel_block2 li .channel_body2 .text-content2 h6{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    color: #1F1F1F;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
}
.channel_block2 li .channel_body2 .subscrib_block{
    width: 100%;
    display: inline-block;
    position: relative;
}
.channel_block2 li .channel_body2 .subscrib_block .ul-subscri{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
}
.channel_block2 li .channel_body2 .subscrib_block .ul-subscri li{
    list-style: none;
    width: calc(100% / 3);
    padding-right: 0;
}
.channel_block2 li .channel_body2 .subscrib_block .ul-subscri li h6{
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #9B9EAD;
    letter-spacing: -0.19px;
    line-height: 19px;
    margin-bottom: 8px;
    text-align: center;
}
.channel_block2 li .channel_body2 .subscrib_block .ul-subscri li p{
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: #1F1F1F;
    letter-spacing: 0px;
    line-height: 19px;
    margin-bottom: 0;
    text-align: center;
}
.channel_block2 li .channel_body2 .subscrib_block .ul-subscri li:first-child p{
    color: #F44336;
}
.channel_block2 li .channel_body2 .subscrib_block .ul-subscri li:last-child p{
    color: #21C986;
}
.channel_block2 li .channel_body2 .butto_block a{
    width: 100%;
    height: 35px;
    max-width: 150px;
    display: inline-block;
    position: relative;
    border:0;
    border-radius: 15px;
    padding: 8px 53px;
    background: #F44336 url('../../assets/image/playW-icon.svg') no-repeat 15% 50%;
    color: #FFFFFF;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    letter-spacing: 0.75px;
    line-height: 19px;
    text-align: center;
    text-decoration: none;
}
.channel_block2 li .channel_body2 .butto_block a:hover,
.channel_block2 li .channel_body2 .butto_block a:focus{
    outline: 0;
    text-decoration: none;
}
.yt-tab-wrap li .tab-search .btn-search{
    width: 100%;
    height: 36px;
    border:0;
    max-width: 39px;
    border-radius: 7px;
    background: #FFFFFF url('../../assets/image/search_icon.svg') no-repeat 50% 50%;
    display: inline-block;
    margin-bottom: 0;
    float: right;
}
.yt-tab-wrap li .tab-search .btn-search:hover,
.yt-tab-wrap li .tab-search .btn-search:focus{
    outline: 0;
}
.yt-tab-wrap li .tab-search .btn-search.active{
    background: #FFFFFF url('../../assets/image/close-icon.svg') no-repeat 50% 50%;
    background-size: 15px;
}
@media screen and (min-width:1024px){
    .yt-tab-wrap li .tab-search .btn-search{
        display: none;
    }
}
@media screen and (max-width:1023px){
    .youtube_body2 {
        padding: 69px 0;
    }
    .youtube_body2 h1{
        font-size: 34px;
    }
    .youtube_content{
        margin-top: 50px;
    }
    .yt-tab-wrap li:first-child{
        order: 3;
    }
    .yt-tab-wrap li:nth-child(2){
        order: 2;
    }
    .yt-tab-wrap li:nth-child(2) .lang_block .click-block label{
        display: none;
    }
    .yt-tab-wrap li .lang_block,
    .yt-tab-wrap li .tab-search{
        max-width: 100%;
    }
    .yt-tab-wrap li .lang_block .click-block label{
        padding-left: 20px;
        font-size: 15px;
    }
    .yt-tab-wrap li .lang_block .click-block{
        height: 36px;
    }
    .yt-tab-wrap li:last-child .lang_block .click-block{
        min-width: 162px;
    }
    .yt-tab-wrap li:nth-child(2) .lang_block .click-block{
        min-width: 120px;
    }
    .yt-tab-wrap li .lang_block .click-block .input_youtube{
        width: 100%;
        padding: 9px 35px 9px 0px;
    }
    .yt-tab-wrap li .lang_block .click-block .input_youtube{
        height: 36px;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        padding-right: 10px;
        margin-left: 35px;
    }
    .yt-tab-wrap li .lang_block .click-block .input_youtube[name="sort_popular"]{
        background-position: 93% 53%;
        background-size: 12px 7.5px;
        width: 66%;
        /* text-align: center; */
    }
    .yt-tab-wrap li .lang_block .drop_block2{
        top:50%;
        left:50%;
        transform: translate(-50%, -6%);
    }
    .yt-tab-wrap li .tab-search .form-youtube{
        width: 100%;
        height: 36px;
        padding: 5px 15px;
        font-size: 14px;
        display: none;
    }
    .channel_block2 li .channel_body2{
        padding: 17px 17.5px;
        display: inline-block;
        position: relative;
    }
    .channel_block2 li .channel_body2 .num-bl,
    .channel_block2 li .channel_body2 .text-content2,
    .channel_block2 li .channel_body2 .butto_block{
        float: left;
    }
    .channel_block2 li .channel_body2 .text-content2{
        padding-left: 13px;
        width: 63%;
    }
    .channel_block2 li .channel_body2 .text-content2 h6{
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
    }
    .channel_block2 li .channel_body2 .butto_block a{
        max-width: 100px;
        padding: 8px 35px;
    }
    .channel_block2 li .channel_body2 .subscrib_block{
        margin-top: 29px;
    }
    .yt-tab-wrap li .tab-search{
        display: block;
        max-width: 39px;
    }
    .yt-tab-wrap li .tab-search .form-youtube{
        width: 0;
        transition: width 0.3s linear;
    }
    .yt-tab-wrap li .tab-search .form-youtube.active{
        width: calc(100% - 48px);
        display: inline-block;
    }
    .yt-tab-wrap li:first-child{
        max-width: 39px;
    }
    .yt-tab-wrap li.active-search:first-child{
        max-width: 100%;
    }
    .yt-tab-wrap li.active-search:first-child .tab-search{
        max-width: 100%;
    }
    .channel_block2 li .channel_body2 .butto_block{
        float: right;
    }
    .yt-tab-wrap li .lang_block .drop_block2 .menu_drop li:first-child{
        max-width: 100%;
    }
}
@media screen and (max-width:767px){
    .youtube_body2 {
        padding: 69px 0;
    }
    .youtube_body2 h1{
        font-size: 34px;
    }
    .youtube_content{
        margin-top: 50px;
    }
    .yt-tab-wrap li:first-child{
        order: 3;
    }
    .yt-tab-wrap li:nth-child(2){
        order: 2;
    }
    .yt-tab-wrap li:nth-child(2) .lang_block .click-block label{
        display: none;
    }
    .yt-tab-wrap li .lang_block,
    .yt-tab-wrap li .tab-search{
        max-width: 100%;
    }
    .yt-tab-wrap li .lang_block .click-block label{
        padding-left: 9px;
        font-size: 10px;
    }
    .yt-tab-wrap li .lang_block .click-block{
        height: 36px;
    }
    .yt-tab-wrap li:last-child .lang_block .click-block{
        min-width: 162px;
    }
    .yt-tab-wrap li:nth-child(2) .lang_block .click-block{
        min-width: 120px;
    }
    .yt-tab-wrap li .lang_block .click-block .input_youtube{
        width: 100%;
        padding: 8px 9px 8px 0px;
    }
    .yt-tab-wrap li .lang_block .click-block .input_youtube{
        height: 36px;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        padding-right: 10px;
        margin-left: 10px;
    }
    .yt-tab-wrap li .lang_block .click-block .input_youtube[name="sort_popular"]{
        background-position: 93% 53%;
        background-size: 12px 7.5px;
        width: 66%;
    }
    .yt-tab-wrap li .lang_block .drop_block2{
        top:50%;
        left:50%;
        transform: translate(-50%, -6%);
    }
    .yt-tab-wrap li .tab-search .form-youtube{
        width: 100%;
        height: 36px;
        padding: 5px 15px;
        font-size: 14px;
        display: none;
    }
    .channel_block2 li .channel_body2{
        padding: 17px 17.5px;
        display: inline-block;
        position: relative;
    }
    .channel_block2 li .channel_body2 .num-bl,
    .channel_block2 li .channel_body2 .text-content2,
    .channel_block2 li .channel_body2 .butto_block{
        float: left;
    }
    .channel_block2 li .channel_body2 .text-content2{
        padding-left: 13px;
        width: 63%;
    }
    .channel_block2 li .channel_body2 .text-content2 h6{
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
    }
    .channel_block2 li .channel_body2 .butto_block a{
        max-width: 100px;
        padding: 8px 35px;
    }
    .channel_block2 li .channel_body2 .subscrib_block{
        margin-top: 29px;
    }
    .yt-tab-wrap li .tab-search{
        display: block;
        max-width: 39px;
    }
    .yt-tab-wrap li .tab-search .form-youtube{
        width: 0;
        transition: width 0.3s linear;
    }
    .yt-tab-wrap li .tab-search .form-youtube.active{
        width: calc(100% - 48px);
        display: inline-block;
    }
    .yt-tab-wrap li:first-child{
        max-width: 39px;
    }
    .yt-tab-wrap li.active-search:first-child{
        max-width: 100%;
    }
    .yt-tab-wrap li.active-search:first-child .tab-search{
        max-width: 100%;
    }
    .channel_block2 li .channel_body2 .butto_block{
        float: right;
    }
    .yt-tab-wrap li .lang_block .drop_block2 .menu_drop li:first-child{
        max-width: 100%;
    }
}
@media screen and (max-width:320px){
    .yt-tab-wrap li:nth-child(2) .lang_block .click-block {
        min-width: 95px;
    }
    .yt-tab-wrap li:last-child .lang_block .click-block{
        min-width: 140px;
    }
    .yt-tab-wrap li .lang_block .click-block label{
        font-size: 8px;
    }
    .channel_block2 li .channel_body2 .text-content2{
        padding-left: 13px;
        width: 54%;
    }
    .channel_block2 li .channel_body2 .butto_block a {
        max-width: 81px;
        padding: 8px 26px;
    }
    .yt-tab-wrap li .lang_block .click-block .input_youtube{
        width: 100%;
        padding: 8px 9px 8px 0px;
        font-size: 12px;
        text-align: left;
    }
}