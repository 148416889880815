/* pulse animation */
@keyframes pulse_animate{
    0%{transform: scale(1)}
    50%{transform: scale(1.1)}
    100%{transform: scale(1)}
}
@-webkit-keyframes pulse_animate{
    0%{transform: scale(1)}
    50%{transform: scale(1.1)}
    100%{transform: scale(1)}
}
@-moz-keyframes pulse_animate{
    0%{transform: scale(1)}
    50%{transform: scale(1.1)}
    100%{transform: scale(1)}
}
@-o-keyframes pulse_animate{
    0%{transform: scale(1)}
    50%{transform: scale(1.1)}
    100%{transform: scale(1)}
}
@-ms-keyframes pulse_animate{
    0%{transform: scale(1)}
    50%{transform: scale(1.1)}
    100%{transform: scale(1)}
}

/* top to down animation */
@keyframes topToDown{
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(0);
    }
}
@-webkit-keyframes topToDown{
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(0);
    }
}
@-moz-keyframes topToDown{
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(0);
    }
}
@-o-keyframes topToDown{
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(0);
    }
}
@-ms-keyframes topToDown{
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(0);
    }
}
/* left to right */
@keyframes leftToRight{
    0%{transform: translateX(-100%)}
    100%{transform: translateX(0)}
}
@-webkit-keyframes leftToRight{
    0%{transform: translateX(-100%)}
    100%{transform: translateX(0)}
}
@-moz-keyframes leftToRight{
    0%{transform: translateX(-100%)}
    100%{transform: translateX(0)}
}
@-o-keyframes leftToRight{
    0%{transform: translateX(-100%)}
    100%{transform: translateX(0)}
}
@-ms-keyframes leftToRight{
    0%{transform: translateX(-100%)}
    100%{transform: translateX(0)}
}

/* right to left */
@keyframes rightToLeft{
    0%{transform: translateX(100%)}
    100%{transform: translateX(0)}
}
@-webkit-keyframes rightToLeft{
    0%{transform: translateX(100%)}
    100%{transform: translateX(0)}
}
@-moz-keyframes rightToLeft{
    0%{transform: translateX(100%)}
    100%{transform: translateX(0)}
}
@-o-keyframes rightToLeft{
    0%{transform: translateX(100%)}
    100%{transform: translateX(0)}
}
@-ms-keyframes rightToLeft{
    0%{transform: translateX(100%)}
    100%{transform: translateX(0)}
}
/* buzz-out */
@keyframes buzz-out{
    10%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    20%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    30%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    40%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    50%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    60%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    70%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    80%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    90%{-webkit-transform:translateX(1px) rotate(0);transform:translateX(1px) rotate(0)}
    100%{-webkit-transform:translateX(-1px) rotate(0);transform:translateX(-1px) rotate(0)}
}
@-webkit-keyframes buzz-out{
    10%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    20%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    30%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    40%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    50%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    60%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    70%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    80%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    90%{-webkit-transform:translateX(1px) rotate(0);transform:translateX(1px) rotate(0)}
    100%{-webkit-transform:translateX(-1px) rotate(0);transform:translateX(-1px) rotate(0)}
}
@-moz-keyframes buzz-out{
    10%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    20%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    30%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    40%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    50%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    60%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    70%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    80%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    90%{-webkit-transform:translateX(1px) rotate(0);transform:translateX(1px) rotate(0)}
    100%{-webkit-transform:translateX(-1px) rotate(0);transform:translateX(-1px) rotate(0)}
}
@-o-keyframes buzz-out{
    10%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    20%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    30%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    40%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    50%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    60%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    70%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    80%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    90%{-webkit-transform:translateX(1px) rotate(0);transform:translateX(1px) rotate(0)}
    100%{-webkit-transform:translateX(-1px) rotate(0);transform:translateX(-1px) rotate(0)}
}
@-ms-keyframes buzz-out{
    10%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    20%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    30%{-webkit-transform:translateX(3px) rotate(2deg);transform:translateX(3px) rotate(2deg)}
    40%{-webkit-transform:translateX(-3px) rotate(-2deg);transform:translateX(-3px) rotate(-2deg)}
    50%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    60%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    70%{-webkit-transform:translateX(2px) rotate(1deg);transform:translateX(2px) rotate(1deg)}
    80%{-webkit-transform:translateX(-2px) rotate(-1deg);transform:translateX(-2px) rotate(-1deg)}
    90%{-webkit-transform:translateX(1px) rotate(0);transform:translateX(1px) rotate(0)}
    100%{-webkit-transform:translateX(-1px) rotate(0);transform:translateX(-1px) rotate(0)}
}
/* center to zoom */
@keyframes centerToZoom{
    0%{transform: scale(0)}
    100%{transform: scale(1)}
}
@-webkit-keyframes centerToZoom{
    0%{transform: scale(0)}
    100%{transform: scale(1)}
}
@-moz-keyframes centerToZoom{
    0%{transform: scale(0)}
    100%{transform: scale(1)}
}
@-o-keyframes centerToZoom{
    0%{transform: scale(0)}
    100%{transform: scale(1)}
}
@-ms-keyframes centerToZoom{
    0%{transform: scale(0)}
    100%{transform: scale(1)}
}