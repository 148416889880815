.tu_body{
    padding: 84px 0;
}
.tu_body h1{
    font-size: 44px;
    font-family: 'SF Pro Text';
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0;
    color: #000000;
    text-align: center;
    margin-bottom: 0;
}
.term_body{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    margin-top: 112px;
}
.term_body li{
    list-style: none;
    width:100%;
    display: inline-block;
    position: relative;
    margin-bottom: 32px;
}
.term_body li:last-child{
    margin-bottom: 0;
}
.term_body li h6,.term_body li p{
    font-family: 'SF Pro Text';
    font-size: 14px;
    margin-bottom: 0;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: justify;
    color: #000000;
}
.term_body li h6{
    font-weight: bold;
    line-height: 44px;
    font-size: 18px;
}
/* .term_body li:first-child p:last-child{
    margin-top: 16px;
} */
@media screen and (max-width:767px){
    .tu_body{
        padding: 69px 0;
    }
    .tu_body h1{
        font-size: 34px;
    }
    .term_body{
        margin-top: 59px;
    }
    .term_body li h6{
        font-size: 14px;
        line-height: 30px;
        margin-bottom: 15px;
    }
}