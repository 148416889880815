.checkout-page {
    display: flex;
    column-gap: 25px;
    padding-top: 80px;
}

.checkout-page ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.checkout-page h2 {
    font-weight: bold;
    font-size: 26px;
    color: #343434;
    letter-spacing: 0.26px;
    line-height: 31px;
    margin-bottom: 22px;
}

.checkout-page h3 {
    font-weight: bold;
    font-size: 20px;
    color: #343434;
    line-height: 24px;
    margin-bottom: 40px;
}

.checkout-page .plan {
    width: 60%;
}

.checkout-page .subscription {
    width: 40%;
}

.checkout-page .radio li {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #343434;
}

.checkout-page .selection p {
    color: #96A0B5;
    font-weight: 400;
}

.checkout-page .selection p b {
    color: #E70E3B;
    text-decoration: line-through;
    font-weight: 500;
}

.checkout-page .selection .badge {
    font-weight: 400;
    background: #eaecf0;
    border-radius: 100px;
    letter-spacing: 0.14px;
    color: #96A0B5;
    font-size: 14px;
    padding: 4px 10px;
}

.checkout-page .radio input[type="radio"] {
    display: none !important;
    *display: inline;
}

.checkout-page .radio label {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 10px 20px 10px 80px;
    line-height: 28px;
    margin-bottom: 25px;
    box-shadow: 0px 0px 30px #E4EBF1;
    border: 2px solid transparent;
    background-color: #fff;
    border-radius: 10px;
}

.checkout-page .radio input:checked+label {
    border: 2px solid #E70E3B;
}

.checkout-page .radio input+label:before {
    content: "";
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    left: 25px;
    top: 50%;
    margin-top: -17px;
    border: 2px solid #96A0B5;
    background-color: #fff;
    border-radius: 50%;
}

.checkout-page .radio input[type="radio"]:checked+label::before {
    background-color: #E70E3B;
    border-color: #E70E3B;
}

.checkout-page .radio input[type="radio"]:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 37px;
    margin-top: -11px;
    transform: rotate(45deg);
    height: 17px;
    width: 10px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
}

.checkout-page .payment-method {
    background: #fff;
    padding: 25px 25px 1px;
    border-radius: 10px;
}

.checkout-page .payment-method label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid #96A0B5;
    padding: 15px 15px 15px 55px;
    color: #96A0B5;
}

.checkout-page .payment-method .radio input+label:before {
    width: 26px;
    height: 26px;
    margin-top: -13px;
    left: 15px;
}

.checkout-page .payment-method .radio input:checked+label {
    border: 1px solid #96A0B5;
    color: #343434;
}

.checkout-page .payment-method .radio input[type="radio"]:checked+label:after {
    left: 20px;
    margin-top: -8px;
    transform: none;
    border: none;
    width: 16px;
    height: 16px;
    background: #E70E3B;
    border-radius: 50%;
}

.checkout-page .payment-method input[type="radio"]:checked+label::before {
    background-color: transparent;
}

.subscription .details {
    background-color: #fff;
    box-shadow: 0px 0px 30px #E4EBF1;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
    position: relative;
}

.subscription .details .payErrorText {
    color: red;
    font-size: 13px;
    text-align: left;
    position: absolute;
    width: 100%;
    left: 32px;
    bottom: 52px;
}

.subscription .details .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    column-gap: 7px;
}

.subscription .details .total {
    border-top: 1px dashed #ccd2de;
    padding-top: 12px;
    margin-top: 12px;
}

.subscription .details .total span {
    font-weight: 600;
    font-size: 20px;
}

.subscription .details span {
    font-weight: 600;
    font-size: 16px;
    color: #343434;
}

.subscription .details p {
    font-weight: 400;
    font-size: 16px;
    color: #96A0B5;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 18px;
}

.subscription .details button {
    background: #E70E3B;
    border-radius: 5px;
    padding: 11px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    border: none;
    margin-top: 30px;
}

.checkout-page .includes li {
    letter-spacing: 0.18px;
    color: #343434;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 22px;
    position: relative;
    padding-left: 30px;
}

.checkout-page .includes li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(45deg);
    height: 18px;
    width: 10px;
    border-bottom: 3px solid #E70E3B;
    border-right: 3px solid #E70E3B;
}

/* Card Modal */

.credit-card .modal-header {
    border: none;
}

.credit-card .modal-footer {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    flex-wrap: nowrap;
    border: none;
}

.credit-card .modal-footer .btn {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    padding: 12px;
}

.credit-card .modal-footer .btn-cancel {
    border: 1px solid #343434;
    border-radius: 5px;
    color: #343434;
    background-color: transparent;
}

.credit-card .modal-footer .btn-confirm {
    color: #fff;
    background-color: #E70E3B;
}

.credit-card .modal-footer .btn-confirm .sendLoader {
    padding-right: 2px;
}

.credit-card .modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    letter-spacing: 0.22px;
    font-weight: 600;
    font-size: 22px;
    color: #343434;
}

.credit-card .modal-title p {
    letter-spacing: 0px;
    font-weight: 600;
    font-size: 24px;
    color: #343434;
}

.credit-card .modal-content {
    padding: 15px;
}

.credit-card input:-webkit-autofill, .credit-card input:-webkit-autofill:hover, .credit-card input:-webkit-autofill:focus {
    border: 1px solid #E8E8E8;
    background-color: transparent;
    -webkit-text-fill-color: #65718b;
}

.card-form .input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px !important;
}

.card-form .input-group label {
    letter-spacing: 0.16px;
    color: #96A0B5;
    font-size: 16px;
    font-weight: 500;
}

.card-form ::-webkit-input-placeholder {
    color: #96A0B5;
}

.card-form ::-moz-placeholder {
    color: #96A0B5;
}

.card-form :-ms-input-placeholder {
    color: #96A0B5;
}

.card-form :-moz-placeholder {
    color: #96A0B5;
}

.card-form .input-group input {
    border: 1px solid #E8E8E8;
    background-color: transparent;
    color: #4a4141;
    padding: 10px 15px;
    border-radius: 3px;
    outline: none;
}

.card-form .half-group {
    display: flex;
    column-gap: 25px;
    justify-content: space-around;
    align-items: center;
}

.card-form .input-group .cardV-icon {
    position: absolute;
    right: 15px;
    top: 69%;
    width: 65px;
    height: 35px;
    transform: translate(0, -50%);
}

.card-form .input-group .cardV-icon.AMERICAN_EXPRESS svg {
    width: 65px;
    height: 35px;
}

.card-form .input-group .cardV-icon.DISCOVER svg {
    width: 65px;
    height: 35px;
}

.card-form .input-group .cardV-icon.JCB {
    width: 40px;
    height: 25px;
    right: 8px;
}

.card-form .input-group .cardV-icon.JCB svg {
    width: 40px;
    height: 25px;
}

.card-form .input-group .cardV-icon.MASTERCARD {
    width: 45px;
    right: 10px;
}

.card-form .input-group .cardV-icon.MASTERCARD svg {
    width: 45px;
    height: 35px;
}

.card-form .input-group .cardV-icon.VISA {
    width: 55px;
    right: 10px;
}

.card-form .input-group .cardV-icon.VISA svg {
    width: 55px;
    height: 35px;
}

.card-form .input-group .cardV-icon.DINERS_CLUB {
    width: 40px;
    right: 10px;
}

.card-form .input-group .cardV-icon.DINERS_CLUB svg {
    width: 40px;
    height: 35px;
}

.card-form .error_text {
    font-size: 12px;
    color: red;
    margin: 5px 5px 0px 2px;
    position: absolute;
    top: 100%;
}

.errorApi {
    font-size: 12px;
    color: red;
    margin: 5px 5px 0px 2px;
    position: absolute;
    bottom: 0;
}

.error_borderFull {
    border-color: #d63e4b !important;
}

.messages-modal {
    text-align: center;
}

.messages-modal .modal-body {
    padding: 35px 0 0;
}

.messages-modal h3 {
    color: #343434;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    margin: 40px 0 10px;
}

.messages-modal p {
    color: #96A0B5;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 12px;
}

.messages-modal button {
    max-width: 200px;
    margin: 0 auto 23px;
}

@media (max-width: 991px) {
    .checkout-page {
        padding-top: 10px;
        flex-direction: column;
    }
    .checkout-page .plan {
        margin-bottom: 15px;
    }
    .checkout-page .plan, .checkout-page .subscription {
        width: 100%;
    }
    .checkout-page h2 {
        line-height: 30px;
        font-size: 20px;
        margin-bottom: 20px;
    }
    .checkout-page h3 {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .checkout-page .radio label {
        font-size: 15px;
        padding: 10px 10px 10px 55px;
        margin-bottom: 15px;
        line-height: 20px;
    }
    .checkout-page .radio label p {
        margin: 5px 0;
    }
    .checkout-page .radio input+label:before {
        width: 28px;
        height: 28px;
        left: 15px;
        margin-top: -14px;
    }
    .checkout-page .radio input[type="radio"]:checked+label:after {
        left: 25px;
        margin-top: -10px;
        height: 15px;
        width: 9px;
    }
    .checkout-page .payment-method .radio input+label:before {
        width: 22px;
        height: 22px;
        margin-top: -11px;
        left: 10px;
        border-width: 1px;
    }
    .checkout-page .payment-method .radio input[type="radio"]:checked+label:after {
        left: 14px;
        margin-top: -7px;
        width: 14px;
        height: 14px;
    }
    .checkout-page .payment-method .radio input+label {
        padding: 8px 8px 8px 40px;
    }
    .checkout-page .payment-method {
        padding: 15px 15px 1px;
    }
    .subscription .details {
        padding: 15px;
        margin-bottom: 20px;
    }
    .subscription .details span {
        font-size: 14px;
    }
    .checkout-page .includes li {
        padding-left: 30px;
        margin-bottom: 15px;
        font-size: 16px;
    }
    .checkout-page .includes li:before {
        height: 16px;
        border-width: 2px;
        width: 8px;
        left: 5px;
    }
    .subscription .details .total {
        padding-top: 10px;
        margin: 10px 0 5px;
    }
    .subscription .details .total span {
        font-size: 16px;
    }
    .subscription .details p {
        font-size: 14px;
        margin-top: 12px;
    }
    .subscription .details button {
        margin-top: 12px;
        font-size: 16px;
        padding: 10px;
    }
}

@media (max-width: 767px) {
    .credit-card .modal-content {
        padding: 0;
    }
    .card-form .half-group {
        flex-direction: column;
    }
    .credit-card .modal-title p, .credit-card .modal-title {
        font-size: 18px;
    }
    .credit-card .modal-footer {
        column-gap: 10px;
        padding-top: 0;
    }
    .credit-card .modal-footer .btn {
        font-size: 16px;
        line-height: 20px;
        padding: 8px;
    }
    .messages-modal h3 {
        font-size: 20px;
        line-height: 24px;
        margin: 25px 0 10px;
    }
    .messages-modal p {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 20px;
    }
}

@media (max-width: 335px) {
    .subscription .details {
        padding: 12px;
    }
    .subscription .details span {
        font-size: 13px;
    }
    .credit-card .modal-title p, .credit-card .modal-title {
        font-size: 16px;
    }
}